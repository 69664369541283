import React, { Fragment, useState } from 'react'
import { useEffect } from 'react';


const SwitchEmployeeIcon = ({ color }) => {
    return (
        <>
            <svg width="198" height="200" viewBox="0 0 198 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M41.5022 46.6086C54.3714 46.6086 64.804 36.176 64.804 23.3068C64.804 10.4375 54.3714 0.00488281 41.5022 0.00488281C28.6329 0.00488281 18.2003 10.4375 18.2003 23.3068C18.2003 36.176 28.6329 46.6086 41.5022 46.6086Z" fill={color} />
                <path d="M48.5194 50.4761H34.4849C25.5213 50.4761 16.9242 54.0346 10.5828 60.3697C4.2414 66.7049 0.674399 75.2984 0.665564 84.2621V96.0964C0.663338 96.6106 0.763257 97.1202 0.959522 97.5955C1.15579 98.0708 1.44449 98.5024 1.80888 98.8652C2.17327 99.228 2.60609 99.5149 3.08223 99.7091C3.55836 99.9034 4.06833 100.001 4.58255 99.9967H78.4218C78.936 100.001 79.446 99.9034 79.9221 99.7091C80.3983 99.5149 80.8311 99.228 81.1955 98.8652C81.5599 98.5024 81.8486 98.0708 82.0448 97.5955C82.2411 97.1202 82.341 96.6106 82.3388 96.0964V84.2621C82.33 75.2984 78.763 66.7049 72.4215 60.3697C66.0801 54.0346 57.4831 50.4761 48.5194 50.4761Z" fill={color} />
                <path d="M156.511 146.617C169.381 146.617 179.813 136.184 179.813 123.315C179.813 110.446 169.381 100.013 156.511 100.013C143.642 100.013 133.21 110.446 133.21 123.315C133.21 136.184 143.642 146.617 156.511 146.617Z" fill={color} />
                <path d="M163.529 150.484H149.494C140.531 150.484 131.933 154.043 125.592 160.378C119.251 166.713 115.684 175.306 115.675 184.27V196.104C115.673 196.619 115.773 197.128 115.969 197.604C116.165 198.079 116.454 198.51 116.818 198.873C117.183 199.236 117.615 199.523 118.091 199.717C118.568 199.911 119.078 200.009 119.592 200.005H193.431C193.941 200.005 194.446 199.904 194.917 199.709C195.388 199.514 195.817 199.228 196.177 198.867C196.538 198.507 196.824 198.078 197.019 197.607C197.214 197.136 197.315 196.631 197.315 196.121V184.287C197.31 175.326 193.75 166.733 187.415 160.395C181.08 154.058 172.489 150.493 163.529 150.484Z" fill={color} />
                <path d="M174.696 50.259C173.927 49.8392 173.082 49.5752 172.211 49.482C171.34 49.3889 170.458 49.4684 169.618 49.7161C168.777 49.9638 167.994 50.3748 167.312 50.9256C166.63 51.4763 166.064 52.1561 165.645 52.9259L164.429 55.1594C160.727 45.819 154.609 37.6305 146.701 31.433C138.793 25.2355 129.379 21.2517 119.425 19.8899C118.542 19.7354 117.638 19.7603 116.765 19.9633C115.892 20.1663 115.069 20.5431 114.345 21.0713C113.621 21.5995 113.011 22.2681 112.552 23.0373C112.092 23.8065 111.792 24.6605 111.67 25.5482C111.548 26.4359 111.606 27.3391 111.841 28.2039C112.075 29.0686 112.482 29.8772 113.036 30.5813C113.591 31.2853 114.281 31.8705 115.067 32.3017C115.852 32.7329 116.717 33.0013 117.608 33.091C125.432 34.1689 132.812 37.3642 138.952 42.3318C145.092 47.2993 149.757 53.8502 152.444 61.2766L147.11 58.3263C146.342 57.862 145.488 57.5577 144.599 57.4315C143.71 57.3054 142.805 57.3601 141.938 57.5923C141.07 57.8246 140.259 58.2295 139.552 58.783C138.845 59.3365 138.257 60.027 137.824 60.8132C137.39 61.5993 137.12 62.465 137.029 63.3581C136.938 64.2513 137.029 65.1536 137.295 66.011C137.562 66.8683 137.999 67.663 138.58 68.3474C139.161 69.0319 139.874 69.5919 140.677 69.9939L158.111 79.5114L158.411 79.678H158.511C158.882 79.8802 159.273 80.0422 159.678 80.1614L160.028 80.2614C160.336 80.329 160.648 80.3791 160.962 80.4114H161.528C161.645 80.4197 161.762 80.4197 161.878 80.4114C162.039 80.4196 162.201 80.4196 162.362 80.4114H162.812C163.123 80.3572 163.429 80.2792 163.728 80.1781H164.012C165.5 79.6101 166.736 78.5281 167.495 77.1278L177.346 59.3097C177.767 58.5415 178.032 57.6979 178.126 56.8272C178.22 55.9566 178.142 55.0758 177.896 54.2353C177.65 53.3948 177.241 52.6111 176.692 51.9288C176.143 51.2465 175.464 50.6791 174.696 50.259Z" fill={color} />
                <path d="M23.3172 149.734C24.0864 150.153 24.9309 150.417 25.8022 150.511C26.6736 150.604 27.5548 150.524 28.3954 150.276C29.236 150.029 30.0195 149.618 30.7012 149.067C31.3828 148.516 31.9492 147.837 32.3679 147.067L33.5847 144.833C37.2862 154.174 43.4044 162.362 51.3124 168.56C59.2204 174.757 68.6338 178.741 78.5883 180.103C79.4709 180.257 80.3756 180.232 81.2484 180.029C82.1212 179.826 82.9441 179.449 83.6679 178.921C84.3918 178.393 85.0018 177.724 85.4614 176.955C85.921 176.186 86.2209 175.332 86.343 174.444C86.4652 173.557 86.4072 172.654 86.1724 171.789C85.9377 170.924 85.531 170.115 84.9768 169.411C84.4225 168.707 83.732 168.122 82.9465 167.691C82.161 167.26 81.2967 166.991 80.4051 166.902C72.5814 165.824 65.201 162.628 59.0613 157.661C52.9217 152.693 48.2562 146.142 45.569 138.716L50.9027 141.633C52.4305 142.33 54.1651 142.425 55.7598 141.898C57.3545 141.372 58.6919 140.263 59.5045 138.794C60.3172 137.324 60.5454 135.602 60.1435 133.972C59.7416 132.341 58.7392 130.922 57.3366 129.999L39.8685 120.415L39.5685 120.248H39.4685C39.0978 120.046 38.7067 119.884 38.3017 119.765L37.9517 119.665C37.6436 119.597 37.332 119.547 37.0183 119.515H36.4516C36.335 119.506 36.2181 119.506 36.1015 119.515C35.9405 119.506 35.7792 119.506 35.6182 119.515H35.1681C34.857 119.569 34.5505 119.647 34.2514 119.748H33.968C32.4797 120.316 31.2439 121.398 30.4844 122.798L20.667 140.683C20.2465 141.451 19.9815 142.295 19.8872 143.165C19.7928 144.036 19.8709 144.917 20.1171 145.757C20.3632 146.598 20.7725 147.382 21.3215 148.064C21.8706 148.746 22.5487 149.313 23.3172 149.734Z" fill={color} />
            </svg>
        </>
    )
}

export default SwitchEmployeeIcon