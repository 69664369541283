import React, { useEffect, useState } from "react";
import Card from "../components/atoms/Card";
// import PlanningIcon from "../static/icons/PlanningWhite.svg";
// import AddEmployeeIcon from "../static/icons/AddEmployeeWhite.svg";
// import DimonaIcon from "../static/icons/DimonaWhite.svg";
// import UurrosterIcon from "../static/icons/Uurrooster.svg";
// import EmployeeAvailabilityIcon from "../static/icons/EmployeeAvailability.svg";
// import QRCode from "../static/icons/QRCode.svg";
// import HolidaysIcon from "../static/icons/ManageHoliday.svg";
// import DeviceIcon from "../static/icons/DeviceActivate.svg";
import Messageboard from "../components/molecules/Messageboard";
import { t } from "../translations/Translation";
// import Uurrooster from "./Uurrooster";
import PlanningWhite from "../static/icons/PlanningWhite";
import Uurrooster from "../static/icons/Uurrooster";
import EmployeeAvailability from "../static/icons/EmployeeAvailability";
import AddEmployeeWhite from "../static/icons/AddEmployeeWhite";
import QRCode from "../static/icons/QRCode";
import ManageHoliday from "../static/icons/ManageHoliday";
import DimonaWhite from "../static/icons/DimonaWhite";
import DeviceActivate from "../static/icons/DeviceActivate";
import InviteEmployee from "../static/icons/InviteEmployee"
import InviteEmployeePopup from "../components/molecules/InviteEmployeePopup";
import { ToastContainer } from "react-toastify";
import ActivateDevice from "../components/molecules/ActivateDevice";
import { APICALL as AXIOS } from "../services/AxiosServices";
import { GetNotifications } from "../routes/ApiEndPoints";
import QRConfigurationPopup from "../components/molecules/QRConfigurationPopup";
import CustomPopup from "../utilities/popup/CustomPopup";
import { useNavigate } from "react-router-dom";
import OverviewIcon from '../static/icons/Overview.js'


export default function Dashboard() {

    const mainTabStyle = "col-md-12 H-27 mt-2 mr-2 mb-2 shadow background-indii-blue text-center text-white flex-grow-1";
    const subTabStyle1 = "col-md-12 H-27 mt-2 ml-2 mb-2 shadow text-center border-0";
    const subTabStyle2 = "col-md-12 H-27 mt-2 mx-2 mb-2  shadow text-center border-0";

    const [popup, setPopup] = useState(false);
    const [activatePopup, setActivatePopup] = useState(false);
    // const [messages, setMessages] = useState([])
    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];
    const [qrPopup, setQrPopup] = useState(false)
    const [manageInvitesPopup, setManageInvitesPopup] = useState(false);


    const dashboardMainTabs = [
        { title: t('PLANNING'), icon: <PlanningWhite />, styleClass: mainTabStyle, actionLink: "/manage-plannings", permission: 'planning' },
        { title: t('NEW_EMPLOYEE'), icon: <AddEmployeeWhite />, styleClass: mainTabStyle, actionLink: "/add-employees", permission: 'add_employee' },
        { title: t('DIMONA'), icon: <DimonaWhite />, styleClass: mainTabStyle, actionLink: "/manage-plannings#dimona", permission: 'dimona_overview' },
        // Dashboard content
        { title: t('TIME_TABLE'), icon: <Uurrooster />, styleClass: subTabStyle1, actionLink: "/uurrooster", permission: 'timetable' },
        { title: t('QRCODE'), icon: <QRCode />, styleClass: subTabStyle1, actionLink: "", permission: 'download_qr_code', ActionFunction: setQrPopup },
        { title: t('DEVICE_ACTIVATE'), icon: <DeviceActivate />, styleClass: subTabStyle1, ActionFunction: setActivatePopup, permission: 'activate_device' },

        { title: t('AVAILABILITY'), icon: <EmployeeAvailability />, styleClass: subTabStyle2, actionLink: "/manage-employees#employee_availability", permission: 'employee_availability' },
        { title: t('HOLIDAY'), icon: <ManageHoliday />, styleClass: subTabStyle2, actionLink: "/manage-plannings#holiday", permission: 'holiday_overview' },
        { title: t('INVITE_EMPLOYEE'), icon: <InviteEmployee />, styleClass: subTabStyle2, ActionFunction: setManageInvitesPopup, permission: 'invite_employee' },
    ]

    let newArray = dashboardMainTabs.filter((val, index) => UserPermissions?.includes(val.permission))

    const chunkArray = (arr, size) => {
        const chunks = [];
        for (let i = 0; i < arr.length; i += size) {
            chunks.push(arr.slice(i, i + size));
        }
        return chunks;
    };
    const chunkedCards = chunkArray(newArray, 3);

    // Dashboard content
    // const dashboardSubTabs1 = [
    //     { title: t('TIME_TABLE'), icon: <Uurrooster />, styleClass: subTabStyle1, actionLink: "/uurrooster", permission: 'timetable' },
    //     { title: t('QRCODE'), icon: <QRCode />, styleClass: subTabStyle1, actionLink: "", permission: 'download_qr_code', ActionFunction: setQrPopup },
    //     { title: t('DEVICE_ACTIVATE'), icon: <DeviceActivate />, styleClass: subTabStyle1, ActionFunction: setActivatePopup, permission: 'activate_device' },
    // ]

    // const dashboardSubTabs2 = [
    //     { title: t('AVAILABILITY'), icon: <EmployeeAvailability />, styleClass: subTabStyle2, actionLink: "/manage-employees#employee_availability", permission: 'employee_availability' },
    //     { title: t('HOLIDAY'), icon: <ManageHoliday />, styleClass: subTabStyle2, actionLink: "/manage-plannings#holiday", permission: 'holiday_overview' },
    //     { title: t('INVITE_EMPLOYEE'), icon: <InviteEmployee color="#073763" />, styleClass: subTabStyle2, ActionFunction: setManageInvitesPopup, permission: 'invite_employee' },
    // ]

    // Messages to display in message board (API will be called to fetch this data)
    const messages = [
        { message: "Happy New Year!!", styleClass: "indii-message text-blue" },
        { message: "Don't forget to check who can work as flex next quarter.", styleClass: "indii-message text-blue" },
        { message: "Dimona failure code 00000-000 server government offline.", styleClass: "dimona-message text-red" },
        { message: "Dimona failure code 90378-460 Laatijdige aangifte.", styleClass: "dimona-message text-red" },
        { message: "Dimona failure code 00024-003 Ongeldig rijkregisternummer.", styleClass: "dimona-message text-red" },
        { message: "Dimona failure code 00024-003 Ongeldig rijkregisternummer.", styleClass: "dimona-message text-red" },
        { message: "Leon can't work tomorrow because of illness.", styleClass: "employee-message text-green" },
        { message: "Leon has applied for a holiday from December 1st till December 31st.", styleClass: "employee-message text-green" },
        { message: "Leon has applied for a holiday from December 1st till December 31st.", styleClass: "employee-message text-green" },
        { message: "Leon has applied for a holiday from December 1st till December 31st.", styleClass: "employee-message text-green" },
        { message: "Leon has applied for a holiday from December 1st till December 31st.", styleClass: "employee-message text-green" },
        { message: "Leon has applied for a holiday from December 1st till December 31st.", styleClass: "employee-message text-green" },
        { message: "Leon has applied for a holiday from December 1st till December 31st.", styleClass: "employee-message text-green" },
    ]
    const navigate = useNavigate()
    useEffect(() => {
        // AXIOS.service(GetNotifications, "GET")
        //     .then((result) => {
        //         if (result.success) {
        //             // setMessages(result.data)
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     })
    }, [])


    const handleInviteEmployee = () => {
        setManageInvitesPopup(false)
        setPopup(true)
    };

    const GetModalBody = () => {
        return (
            <div className="d-flex justify-content-center m-2">
                <div className='background-indii-blue text-center text-white mr-4 p-4 width-10 shadow rounded align-items-center' onClick={() => navigate('/manage-invites')}>
                    <span className='invite-icon'>{<OverviewIcon color="#FFF" />}</span>
                    <h5 className="mb-0 pt-2">{t('OVERVIEW_PAGE')}</h5>
                </div>
                <div className='background-indii-blue text-center text-white p-4 width-10 shadow rounded align-items-center' onClick={handleInviteEmployee}>
                    <span className='invite-icon'>{<InviteEmployee color="#FFF" />}</span>
                    <h5 className=" mb-0 pt-2">{t('INVITE_EMPLOYEE')}</h5>
                </div>
            </div>
        )
    }

    return (
        <div className="right-container bg-white">
            {popup && <InviteEmployeePopup onHide={() => setPopup(false)}></InviteEmployeePopup>}
            {manageInvitesPopup && <CustomPopup size="m" title={t('INVITE_EMPLOYEE')} body={GetModalBody()} onHide={() => setManageInvitesPopup(false)} close={true} footer header></CustomPopup>}
            {activatePopup && <ActivateDevice onHide={() => setActivatePopup(false)}></ActivateDevice>}
            {qrPopup && <QRConfigurationPopup show={qrPopup} onHide={setQrPopup}></QRConfigurationPopup>}
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <div className="col-md-12 mb-0 d-flex mt-2 pt-1 dashboard_height ">  {/* mb-5 */}
                <div className="col-md-12 d-flex flex-column">
                    {chunkedCards.map((row, rowIndex) => (
                        <div className="grid-container gap-3" key={rowIndex}>
                            {row.map((val, index) => {
                                let updatedIcon = React.cloneElement(val.icon, (index % 3 == 0 ? { color: "#fff" } : { color: "#073763" }));
                                return (
                                    <Card key={val.title} title={val.title} icon={updatedIcon} actionLink={val.actionLink} styleClass={index % 3 == 0 ? mainTabStyle : index % 2 == 0 || 1 ? subTabStyle2 : subTabStyle1} ActionFunction={val.ActionFunction} view={'dashboard'}></Card>

                                )
                            })}
                        </div>
                    ))}
                    {/* {
                        newArray.map((val, index) => {
                            if (UserPermissions?.includes(val.permission)) {
                                let updatedIcon = React.cloneElement(val.icon, (index % 3 == 0 ? { color: "#fff" } : { color: "#073763" }));
                                return (
                                    // <div className="col-md-4">
                                    <Card key={val.title} title={val.title} icon={updatedIcon} actionLink={val.actionLink} styleClass={index % 3 == 0 ? mainTabStyle : index % 2 == 0 || 1 ? subTabStyle2 : subTabStyle1} ActionFunction={val.ActionFunction} view={'dashboard'}></Card>
                                    // </div>
                                )
                            }
                        })
                    } */}
                    {/* </div> */}
                    {/* <div className="col-md-4">
                    {
                        dashboardSubTabs1.map((val, index) => {
                            if (UserPermissions?.includes(val.permission)) {
                                return (
                                    <Card key={val.title} title={val.title} icon={val.icon} actionLink={val.actionLink} styleClass={val.styleClass} ActionFunction={val.ActionFunction} view={'dashboard'}></Card>
                                )
                            }
                        })
                    }
                </div>
                <div className="col-md-4">
                    {
                        dashboardSubTabs2.map((val, index) => {
                            if (UserPermissions?.includes(val.permission)) {
                                return (
                                    <Card key={val.title} title={val.title} icon={val.icon} actionLink={val.actionLink} styleClass={val.styleClass} ActionFunction={val.ActionFunction} view={'dashboard'}></Card>
                                )
                            }
                        })
                    }
                </div> */}
                </div>
                {/* <Messageboard Messages={messages}></Messageboard> */}
            </div>
        </div>
    )
}
