import React from "react";
import { t } from "../../translations/Translation"

const WeekDetailsTable = ({ weekDetails }) => {

  const headers = [t("DAY"), t("START_TIME"), t("END_TIME"), t("CONTRACT_HOURS"), t("LOCATION"), t("WORKSTATION"), t("FUNCTIONS")]
  return (
    <div>
      {weekDetails.map((week, index) => (
        <div key={index} className="mb-2">
          <h5>{week.week}</h5>
          <div className="overflow-auto">
          {/* Table for plannings */}
            <table border="1" cellPadding="8" >
              <thead>
                <tr className="bg-grey">
                  {headers.map((header, idx) => (
                    <th key={idx}>{header?.replace(/_/g, ' ').toUpperCase()}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {week.plannings.map((planning, idx) => (
                  <tr key={idx}>
                    {Object.entries(planning).map(([key, value], idy) => (
                      <td
                        key={idy}
                        style={{
                          backgroundColor: (typeof value === 'object' && value.changed) ? '#ffeb3b' : 'transparent', // Highlight if value is an object
                        }}
                      >
                        {/* Check if the value is an object, and display value.value if so */}
                        {typeof value === 'object' && value !== null ? value.value : value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};


export { WeekDetailsTable };
