import * as React from "react";
const FilterSettingIcon = (props) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.3695 20.3691H27.9266C27.8259 20.0671 27.7252 19.765 27.5574 19.4966L28.5641 18.4899C28.6312 18.4228 28.6648 18.3557 28.6648 18.255C28.6648 18.1543 28.6312 18.0872 28.5641 18.0201L26.685 16.1074C26.5507 15.9731 26.3494 15.9731 26.2152 16.1074L25.2085 17.1141C24.94 16.9798 24.638 16.8456 24.336 16.7449V15.3356C24.336 15.1678 24.1682 15 24.0004 15H21.3159C21.1481 15 20.9804 15.1678 20.9804 15.3356V16.7785C20.6784 16.8792 20.3763 16.9798 20.1079 17.1476L19.1012 16.1409C18.967 16.0067 18.7656 16.0067 18.6314 16.1409L16.7187 18.0201C16.6516 18.0872 16.618 18.1543 16.618 18.255C16.618 18.3557 16.6516 18.4228 16.7187 18.4899L17.7254 19.4966C17.5912 19.7651 17.4569 20.0671 17.3563 20.3691H15.9469C15.7791 20.3691 15.6113 20.5369 15.6113 20.7046V23.3892C15.6113 23.5569 15.7791 23.7247 15.9469 23.7247H17.3898C17.4905 24.0267 17.5912 24.3287 17.759 24.5972L16.7523 25.6039C16.6516 25.671 16.618 25.7381 16.618 25.8388C16.618 25.9395 16.6516 26.0066 16.7187 26.0737L18.6314 27.9864C18.7656 28.1206 18.967 28.1206 19.1012 27.9864L20.1079 26.9797C20.3764 27.1139 20.6784 27.2482 20.9804 27.3488V28.7582C20.9804 28.926 21.1482 29.0938 21.3159 29.0938H24.0005C24.1682 29.0938 24.336 28.926 24.336 28.7582V27.3153C24.638 27.2146 24.94 27.1139 25.2085 26.9461L26.2152 27.9528C26.2823 28.02 26.3494 28.0535 26.4501 28.0535C26.5508 28.0535 26.6179 28.02 26.685 27.9528L28.5977 26.0401C28.6648 25.973 28.6984 25.9059 28.6984 25.8052C28.6984 25.7045 28.6648 25.6374 28.5977 25.5703L27.591 24.5636C27.7252 24.2952 27.8595 23.9932 27.9601 23.6912H29.3695C29.5373 23.6912 29.7051 23.5234 29.7051 23.3556V20.6711C29.7051 20.5368 29.5373 20.3691 29.3695 20.3691ZM22.6582 25.067C20.9804 25.067 19.6382 23.7247 19.6382 22.047C19.6382 20.3693 20.9805 19.027 22.6582 19.027C24.3359 19.027 25.6782 20.3693 25.6782 22.047C25.6782 23.7247 24.3359 25.067 22.6582 25.067Z"
      fill={props.color}
    />
    <path
      d="M11.804 30C11.679 30 11.554 29.977 11.434 29.929C11.053 29.777 10.803 29.409 10.804 29V13.451L0.255029 1.667C-0.00797066 1.373 -0.0739706 0.952 0.0870294 0.592C0.248029 0.231 0.605029 0 1.00003 0H28.224C28.619 0 28.976 0.232 29.137 0.592C29.298 0.952 29.232 1.373 28.969 1.667L18.421 13.451V15.104L15.6556 17.5238C15.3442 17.7963 15.2829 18.2574 15.5125 18.6017L15.5457 18.6515C15.8306 19.0788 15.6311 19.6601 15.1439 19.8225C14.8259 19.9285 14.6113 20.2261 14.6113 20.5614V23.8377C14.6113 24.2332 14.8644 24.5844 15.2396 24.7094C15.7211 24.8699 15.9812 25.3903 15.8208 25.8717L15.7756 26.0073C15.7422 26.1073 15.7245 26.2106 15.7218 26.3139L12.531 29.687C12.338 29.891 12.074 30 11.804 30Z"
      fill={props.color}
    />
    <path
      d="M18.1913 23.7001C18.3399 23.521 18.421 23.2952 18.421 23.061V21.403L18.1913 23.7001Z"
      fill={props.color}
    />
  </svg>
);
export default FilterSettingIcon;
