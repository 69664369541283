export const ENV_URL = process.env.REACT_APP_serverURL //'http://dev.api.indii-2.0.infanion.com/' 'https://api.test.indii-new.infanion.com/'
export const REGEX_URL = 'service';
export const BASE_URL = ENV_URL + REGEX_URL;
export const LogoutLink = '';
export const PAGINATE_BY = 10;

//follow the below example to add your url endpoints

// EXAMPLE : export const getUserDetails = BASE_URL + 'account/get-user-details';

export const LoginApiUrl = BASE_URL + '/identity-manager/web-login'
export const LogoutApiUrl = BASE_URL + '/identity-manager/logout'
export const AccessTokenApiUrl = BASE_URL + '/identity-manager/generate-access-token'
export const GenderApiUrl = BASE_URL + '/identity-manager/genders'
export const MaritalStatusApiUrl = BASE_URL + '/identity-manager/marital-statuses'
export const EmployeeCreateApiUrl = BASE_URL + '/identity-manager/user'

// export const GetUserDetailsApiUrl = BASE_URL + '/identity-manager/user-details'
//add all your new urls from here onwards

// Configuration api urls
export const EmployeeTypeApiUrl = BASE_URL + '/masterdata/employee-types'
export const EmployeeTypeOptionsApiUrl = BASE_URL + '/masterdata/employee-types/create'
export const SectorApiUrl = BASE_URL + '/masterdata/sectors'
export const FunctionApiUrl = BASE_URL + '/masterdata/function-titles'
export const GroupFunctionApiUrl = BASE_URL + '/masterdata/function-categories'
export const ContractTypeApiUrl = BASE_URL + '/masterdata/contract-types'
export const HourlyMinimumSalariesApiurl = BASE_URL + '/masterdata/hourly-minimum-salaries'
export const MonthlyMinimumSalariesApiurl = BASE_URL + '/masterdata/monthly-minimum-salaries'
export const getIncrementedSalariesApiUrl = BASE_URL + '/masterdata/salary-increment-calculation'
export const ReasonsApiUrl = BASE_URL + '/masterdata/reasons'
export const SocialSecretaryApiUrl = BASE_URL + '/masterdata/social-secretary'
export const EmailTemplateApiUrl = BASE_URL + '/masterdata/email-templates'
export const ContractTemplateApiUrl = BASE_URL + '/masterdata/contract-templates'
export const InterimAgencyApiUrl = BASE_URL + '/masterdata/interim-agencies'
export const SocialSecretaryEngineApiUrl = BASE_URL + '/masterdata/social-secretary-engine'
export const GetHolidayCodesToLinkApiUrl = BASE_URL + '/masterdata/social-secretary-holiday-configuration'

// Company api urls
export const CompanyApiUrl = BASE_URL + '/masterdata/companies'
export const CompanyUnarchieveUrl = BASE_URL + '/masterdata/undelete-company'
export const CompanyAdditionalApiUrl = BASE_URL + '/masterdata/company-additional-details'
export const LocationApiUrl = BASE_URL + '/masterdata/locations'
export const LocationListApiUrl = BASE_URL + '/masterdata/company-locations'
export const WorkstationApiUrl = BASE_URL + '/masterdata/workstations'
export const WorkstationListApiUrl = BASE_URL + '/masterdata/company-workstations'
export const GetSectorFunctionsApiUrl = BASE_URL + '/masterdata/get-company-linked-functions'
export const ResponsiblePersonApiUrl = BASE_URL + '/masterdata/responsible-persons'
export const CostCenterApiUrl = BASE_URL + '/masterdata/cost-centers'
export const ResponsibleCompaniesApiUrl = BASE_URL + "/masterdata/user-companies-web"
export const CompanyContractTemplateApiUrl = BASE_URL + '/masterdata/company-contract-templates'
export const CompanyConfigUrl = BASE_URL + '/masterdata/company-config/'

// Employee api urls
export const EmployeeApiUrl = BASE_URL + '/masterdata/employees'
export const EmployeeContractApiUrl = BASE_URL + '/masterdata/employee-contracts'
export const EmployeeCommutetApiUrl = BASE_URL + '/masterdata/employee-commute'
export const EmployeeBenefitsApiUrl = BASE_URL + '/masterdata/employee-benefits'
export const GetEmployeeDocumentsApiUrl = BASE_URL + "/masterdata/get-employee-documents"
export const DeleteEmployeeDocumentApiUrl = BASE_URL + "/masterdata/delete-employee-document"
export const GetEmployeesApiUrl = BASE_URL + '/masterdata/get-company-employees'
export const UploadIdCardApiUrl = BASE_URL + '/masterdata/employee-id-card'
export const SendDimonaContractApiUrl = BASE_URL + '/masterdata/send-long-term-dimona'
export const UploadEmployeeFileApiUrl = BASE_URL + '/masterdata/upload-import-employee-file'
export const GetImportedEmployeesApiUrl = BASE_URL + '/masterdata/get-import-employee-files'
export const GetSampleExcelFileApiUrl = BASE_URL + '/masterdata/import-employee-sample-file'
export const GetEmployeeListApiurl = BASE_URL + '/masterdata/get-employee-list'
export const FetchMinSalaryApisUrl = BASE_URL + '/masterdata/employee-function-salary-option'
export const UnarchieveEmployeeApiUrl = BASE_URL + '/masterdata/unarchive-employee'
export const CompanyListToCopyEmployeeApiUrl = BASE_URL + '/masterdata/get-companies-except'
export const CopyEmployeeApiUrl = BASE_URL + '/masterdata/copy-employee-in-company'
export const GetEmployeePermissionApiUrl = BASE_URL + '/masterdata/employee-roles/'
export const GetRoleOptionsApiUrl = BASE_URL + '/masterdata/roles-options'
export const AddCommuteDetailsApiUrl = BASE_URL + '/masterdata/add-one-commute-detail'
export const CheckEmployeeFuturePlansAvailabilityApiUrl = BASE_URL + '/masterdata/check-future-planning-availability'
export const UploadEmployeeDocumentApiUrl = BASE_URL + '/masterdata/upload-employee-document'
export const GetCompanyManagersListApiUrl = BASE_URL + '/masterdata/company-managers'

// UUrrooster api urls
export const UurroosterApiUrl = BASE_URL + '/masterdata/uurrooster'
export const UnAuthUurroosterApiUrl = BASE_URL + '/masterdata/open-uurrooster'

// Setting api urls
export const MealVoucherApiUrl = BASE_URL + '/masterdata/meal-vouchers'
export const CommuteTypesApiUrl = BASE_URL + '/masterdata/commute-types'


// Holiday configuration api urls
export const HolidayCodeApiUrl = BASE_URL + '/masterdata/holiday-codes'
export const HolidayCodeConfigurationApiUrl = BASE_URL + "/masterdata/holiday-code-config"
export const GetHolidaysByStatusApiUrl = BASE_URL + '/masterdata/holidays-list/'
export const UpdateHolidayStatusApiUrl = BASE_URL + '/masterdata/holidays-status'
export const HolidaysApiUrl = BASE_URL + '/masterdata/holidays' //to GET, DELETE, UPDATE holiday

// Planning Apis:
export const FilterOptionsApiUrl = BASE_URL + '/masterdata/planning/get-planning-options'
export const GetMonthlyPlanningApiUrl = BASE_URL + '/masterdata/planning/get-monthly-planning'
export const GetWeeklyPlanningApiUrl = BASE_URL + '/masterdata/planning/get-week-planning'
export const GetEmployeeWeekPlansApiUrl = BASE_URL + '/masterdata/get-week-planning-employee'
export const GetEmployeeOptionsApiUrl = BASE_URL + '/masterdata/get-active-contract-employees'
export const GetPlanCreationOptionsUrl = BASE_URL + '/masterdata/get-employee-plan-creation-options'
export const CreatePlanApiUrl = BASE_URL + '/masterdata/save-plans'
export const DeleteSinglePlan = BASE_URL + '/masterdata/delete-plan/'
export const DeleteWeekPlans = BASE_URL + '/masterdata/delete-week-plans'
export const GetDayPlans = BASE_URL + '/masterdata/planning/get-day-planning'
export const GetPlanDetails = BASE_URL + '/masterdata/planning-details/'
export const GetStartPlanReasonsApiUrl = BASE_URL + '/masterdata/start-plan-reasons'
export const GetStopPlanReasonsApiUrl = BASE_URL + '/masterdata/stop-plan-reasons'
export const StartPlanApiUrl = BASE_URL + '/masterdata/start-plan-by-manager'
export const StopPlanApiUrl = BASE_URL + '/masterdata/stop-plan-by-manager'
export const ClonePlanningApiUrl = BASE_URL + '/masterdata/clone-planning'
export const StartBreakApiUrl = BASE_URL + '/masterdata/start-break'
export const StopBreakApiUrl = BASE_URL + '/masterdata/stop-break'

// Open shift api urls
export const OpenShiftApiUrl = BASE_URL + '/masterdata/open-shifts'
export const OpenShiftApplicationsApiUrl = BASE_URL + '/masterdata/get-open-shift-applications'
export const ApproveOpenShiftApplicationsApiUrl = BASE_URL + '/masterdata/approve-open-shift-application'
export const RejectOpenShiftApplicationsApiUrl = BASE_URL + '/masterdata/reject-open-shift-application'
export const CreateShiftsApiUrl = BASE_URL + '/masterdata/store-planning-shifts'
export const CreateShiftPlanApiUrl = BASE_URL + '/masterdata/create-shift-plan'

// Oth planning api urls
export const CreateOthPlanApiUrl = BASE_URL + '/masterdata/long-term-planning'
export const GetOthPlansApiUrl = BASE_URL + '/masterdata/employee-long-term-plannings/'
export const GetAllOthPlansApiUrl = BASE_URL + '/masterdata/get-all-long-term-plannings'
export const GetOthOptionsApiUrl = BASE_URL + '/masterdata/long-term-planning/create'

// My account api urls
export const UserDetailsApiUrl = BASE_URL + '/masterdata/user-details'
export const UpdateUserDetailsApiUrl = BASE_URL + '/masterdata/employee-update'

// Holiday api urls
export const ChangeReportingManagerForHOliday = BASE_URL + "/masterdata/holidays-change-reporting-manager"
export const PublicHolidayCodeApiUrl = BASE_URL + '/masterdata/public-holidays'
export const EmployeeHolidayCountsOverViewApiUrl = BASE_URL + "/masterdata/employee-holiday-count-overview"
export const EmployeeHolidayCountsApiUrl = BASE_URL + "/masterdata/employee-holiday-count"
export const EditEmployeeHolidayCountsApiUrl = BASE_URL + "/masterdata/edit-employee-holiday-count-overview"

export const EmployeeSignApiUrl = BASE_URL + '/identity-manager/user-signature'
export const SignContractApiUrl = BASE_URL + '/masterdata/sign-plan-contract-manager-flow'
export const EmployeeAvailabilityApiUrl = BASE_URL + '/masterdata/get-employee-availability'
export const GetAvailabilitiesApiUrl = BASE_URL + '/masterdata/get-weekly-availability'

// Rules and params api urls
export const GetDefaultParamApiUrl = BASE_URL + '/masterdata/get-default-parameters'
export const UpdateDefaultParamApiUrl = BASE_URL + '/masterdata/update-default-parameter'

export const GetParametersApiUrl = BASE_URL + '/masterdata/get-parameters'
export const UpdateParameterApiUrl = BASE_URL + '/masterdata/update-parameter'

export const GetCompanyParametersApiUrl = BASE_URL + '/masterdata/get-company-parameters'
export const UpdateCompanyParametersApiUrl = BASE_URL + '/masterdata/update-company-parameter'

export const GetParametersOptionsApiUrl = BASE_URL + '/masterdata/get-manage-parameter-options'

// Dimona api urls
export const EmployeeTypeDimonaConfigurationApiUrl = BASE_URL + "/masterdata/employee-type-dimona-config"
export const GetDimonaPlansApiUrl = BASE_URL + "/masterdata/get-plans-to-send-dimona"
export const SendDimonaApiUrl = BASE_URL + "/masterdata/send-dimona-by-plan"
export const GetDimonaApiUrl = BASE_URL + "/masterdata/dimona-overview"
export const GetDimonaDetailsApiUrl = BASE_URL + "/masterdata/dimona-details/"
export const DimonaErrorCodesApiUrl = BASE_URL + "/masterdata/dimona-error-codes"
export const ForceProcessDimonaApiUrl = BASE_URL + "/masterdata/process-dimona-declaration-response"
export const CancelDimonaApiUrl = BASE_URL + "/masterdata/cancel-dimona"
export const ResendDimonaApiUrl = BASE_URL + "/masterdata/resend-dimona"


// Social secretary Configuration api urls
export const TaxesApiUrl = BASE_URL + "/masterdata/taxes"
export const SalaryCoefficientApiUrl = BASE_URL + "/masterdata/salary-coefficients"
export const OvertimeConfigApiUrl = BASE_URL + "/masterdata/over-time/"
export const WorkCodesApiUrl = BASE_URL + "/masterdata/work-codes"
export const SocialSecretaryWorkCodeApiUrl = BASE_URL + "/masterdata/social-secretary-work-codes"

// Leave api urls
export const AddLeaveApiUrl = BASE_URL + "/masterdata/leaves"
export const GetPlansForLeavesApiUrl = BASE_URL + "/masterdata/get-plans-for-leave"
export const GetLeaveOptionsApiUrl = BASE_URL + "/masterdata/leaves/create"
export const GetEmployeeLeavesApiUrl = BASE_URL + "/masterdata/get-absence-details-for-week"
export const AddShiftLeaveApiUrl = BASE_URL + "/masterdata/shift-leave"
export const GetLeaveDataApiUrl = BASE_URL + "/masterdata/leaves/"

// Invite employee api urls
export const EmployeeInviteApiUrl = BASE_URL + '/masterdata/employee-invitations'
export const ValidateEmployeeInvitation = BASE_URL + '/masterdata/validate-employee-invitations'
export const EmployeeRegistrationApiUrl = BASE_URL + '/masterdata/employee-registration'
export const GetInvitedEmployeesApiUrl = BASE_URL + '/masterdata/employee-invitations'
export const DeleteInviteApiUrl = BASE_URL + '/masterdata/employee-invitations'
export const GetEmployeePreFillDataApiUrl = BASE_URL + '/masterdata/get-employee-invitation-filled-details'

//Translations API urls starts here
// export const getLangaugeList = BASE_URL + 'translations/get-all-languages';
export const fetchTranslations = BASE_URL + '/masterdata/translate';
export const fetchAllTranslations = BASE_URL + '/masterdata/translations';

export const ValidateLocationDashboardAccessTokenForLocation = BASE_URL + '/masterdata/validate-location-dashboard-access-key';
export const GetDashboardAccessTokenForLocation = BASE_URL + '/masterdata/get-dashboard-access-key-for-location';
export const DeactivateLocationOnAllDeviceApiUrl = BASE_URL + '/masterdata/revoke-dashboard-access-key';
export const ActivateDeviceDashboardApiUrl = BASE_URL + '/masterdata/get-dashboard-access-overview';
export const DimonaNamespaceApiUrl = BASE_URL + '/masterdata/dimona-namespace';

//flex salary
export const FlexSalaryCreateOrUpdateApiUrl = BASE_URL + '/masterdata/flex-salary';

// Monthly overview
export const GetWorkedEmployeesApiUrl = BASE_URL + '/masterdata/get-worked-employees-overview'
export const GetEmployeeOverviewApiUrl = BASE_URL + '/masterdata/get-employee-overview'
export const UpdateEmployeeRecordApiUrl = BASE_URL + '/masterdata/update-employee-overview-record'
export const ExportEmployeeRecordApiUrl = BASE_URL + '/masterdata/get-social-secretary-export-file'
export const ExportAttendanceApiUrl = BASE_URL + '/masterdata/export-employees-attendence'
export const ProcessPlanningsApiUrl = BASE_URL + '/masterdata/process-planning-report'
export const GetWorkedEmployeesListToExportApiUrl = BASE_URL + '/masterdata/get-worked-employees-list'

//reset forgot password
export const GetLinkToResetPasswordApiUrl = BASE_URL + '/identity-manager/send-resetlink'
export const ValidatePasswordResetTokenApiUrl = BASE_URL + '/identity-manager/verify-token-expiration';
export const UpdatePasswordApiUrl = BASE_URL + '/identity-manager/update-password';

// resend activation mail
export const ResendActivationMailApiUrl = BASE_URL + '/masterdata/resend-activation-mail';

// Permissions api
export const GetPermissionsApiUrl = BASE_URL + '/masterdata/get-employee-permissions';

//FAQ
export const StoreFaqFileApiURL = BASE_URL + '/masterdata/upload-files';
export const GetFaqFileApiUrl = BASE_URL + '/masterdata/get-file-url';

export const GetResetPasswordLinkApiUrl = BASE_URL + '/identity-manager/send-resetlink'

// Notifications
export const GetNotifications = BASE_URL + '/masterdata/get-all-notifications'

//QR code configuration
export const GetQRConfigurationsApiUrl = BASE_URL + '/masterdata/locations-qr-code-config';
export const ChangeQRConfigurationApiUrl = BASE_URL + '/masterdata/change-qr-refresh-type';
export const GenerateNewQRcode = BASE_URL + '/masterdata/change-qr-code-token';
export const DownloadQRcodeForTypeDailyApiUrl = BASE_URL + '/masterdata/download-qr-codes';
export const DownloadQRcodeForTypeMAnualApiUrl = BASE_URL + '/masterdata/download-qr-code';

//Roles
export const RolesApiUrl = BASE_URL + '/masterdata/roles';
export const GetRolePermissionsApiUrl = BASE_URL + '/masterdata/edit-role-permissions';
export const UpdateRolePermissionsApiUrl = BASE_URL + '/masterdata/update-role-permissions';

//Current plan
export const GetplansFortheDayApiUrl = BASE_URL + '/masterdata/plan-data';
export const StartPlanByEmployeeApiUrl = BASE_URL + '/masterdata/start-plan-by-employee';
export const StopPlanByEmployeeApiUrl = BASE_URL + '/masterdata/stop-plan-by-employee';
export const StartBreakByPlanIdApiUrl = BASE_URL + '/masterdata/start-break-by-employee-web';
export const StopBreakEmployeeApiUrl = BASE_URL + '/masterdata/stop-break-by-employee-web';
export const StopPastPlansApiurl = BASE_URL + '/masterdata/past-plan-stop-employee';
export const GetContractPDFApiUrl = BASE_URL + '/masterdata/get-planning-contract';
export const SignLaterApiUrl = BASE_URL + '/masterdata/sign-later-plan-contract';

//Overtime counter
export const GetOvertimeCountsApiUrl = BASE_URL + '/masterdata/employee-overtime-counts';
export const UpdateOvertimeCountApiUrl = BASE_URL + '/masterdata/employee-overtime-count';

//Exoprt employees
export const GetActiveAndInactiveEmployeesApiUrl = BASE_URL + '/masterdata/get-active-inactive-employees';
export const ExportEmployeeDetailsApiUrl = BASE_URL + '/masterdata/export-data-employees-details';
//Channge password by admin or manager
export const ChangePasswordByAdminOrManagerApiUrl = BASE_URL + '/masterdata/admin/change-password';
export const ChangePasswordByUserApiUrl = BASE_URL + '/identity-manager/employee/change-password';

//Json editor
export const GetDimonaDeclatrationDetailsApiUrl = BASE_URL + '/masterdata/get-dimona-declaration-details';
export const ResendDimonaDeclarationDetailsApiUrl = BASE_URL + '/masterdata/resend-dimona';
//message board
export const GetMessageBoardNotificationsApiUrl = BASE_URL + '/masterdata/message-board-notifications';
export const DeleteNotificationApiUrl = BASE_URL + '/masterdata/delete-notification';

//manual generation of contract
export const GetContractOptionsListForMAnualGeneration = BASE_URL + '/masterdata/generate-contract-options';
export const GenerateContractManuallyApiUrl = BASE_URL + '/masterdata/generate-contract';

export const CancelDimonaIfDimonaExistApiUrl = BASE_URL + '/masterdata/cancel-dimona-and-delete-contract'

//Approve late stop plans
export const GetLatestopPlansRequestsApiUrl = BASE_URL + '/masterdata/get-planning-stop-requests'
export const ApproveLatestopPlansApiUrl = BASE_URL + '/masterdata/approve-planning-stop-requests'
export const EditLatestopPlanrequestApiUrl = BASE_URL + '/masterdata/edit-planning-stop-request'
export const GetLatePlanstopRequestsCountApiUrl = BASE_URL + '/masterdata/get-planning-stop-requests-count'
//company groups
export const CompanyGroupsApiUrl = BASE_URL + '/masterdata/company-groups'

//manage admins
export const ManageAdminsApiUrl = BASE_URL + '/identity-manager/admin'

//notification form
export const GetCompanyAndRoleOptionsForNotificationForm = BASE_URL + '/masterdata/send-notification-options'
export const SendNotificationsDataApiUrl = BASE_URL + '/masterdata/send-notification'
export const NotificationsOverviewApiUrl = BASE_URL + '/masterdata/indii-notification'
//manage employees
export const ManageEmployeesApiUrl = BASE_URL + '/identity-manager/users'
export const UserLinkedCompaniesApiUrl = BASE_URL + '/masterdata/user-linked-companies'
export const MergeEmployeesApiUrl = BASE_URL + '/masterdata/merge-users'
export const UserListToMergeApiUrl = BASE_URL + '/identity-manager/get-users-to-merge'
export const BlockUsersApiUrl = BASE_URL + '/identity-manager/block-user'
export const UnblockUsersApiUrl = BASE_URL + '/identity-manager/unblock-user'
export const DeleteUserFromCompanyApiUrl = BASE_URL + '/masterdata/delete-company-user'

//planning logs
export const GetPlanningLogsApiUrl = BASE_URL + '/masterdata/planning-logs'

//Invoice configurations
export const InvoiceCounterApiUrl = BASE_URL + '/masterdata/invoice-counter-overview'
export const ManagerHistoryApiUrl = BASE_URL + '/masterdata/manager-history'

//approve requests for employee switch
export const GetRequestsForSwitchEmployeeApiUrl = BASE_URL + '/masterdata/switch-plan-requests'
export const ApproveRequestForSwitchEmployeeApiUrl = BASE_URL + '/masterdata/approve-switch-plan-request'
export const RejectRequestForSwitchEmployeeApiUrl = BASE_URL + '/masterdata/reject-switch-plan-request'
export const GetPlanningOverviewStatusApiUrl = BASE_URL + '/masterdata/get-planning-overview-status'

//Notification template
export const NotificationTemplateApiUrl = BASE_URL + '/identity-manager/notification-templates'

//Night hour cost
export const NightHourCostApiUrl = BASE_URL + '/masterdata/night-hour-cost'
export const GetSectorOptionsApiUrl = BASE_URL + '/masterdata/options/sectors'

//company activity log
export const GetCompanyActivityLogsApiUrl = BASE_URL + '/masterdata/company-activity-logs'
export const GetLongtermPlanningActivityLogsApiUrl = BASE_URL + '/masterdata/long-term-activity-log-details'

//Employees day availability
export const GetEmployeesDayAvailabilityApiUrl = BASE_URL + '/masterdata/get-day-availability'
export const GetEmployeeDayPlanningsApiUrl = BASE_URL + '/masterdata/planning/get-employee-day-planning'

export const GetDimonaConnectionStatusApiUrl = BASE_URL + '/masterdata/check-dimona-connection'
export const GetMonthlyOverviewSettingsOptionApiUrl = BASE_URL + '/masterdata/monthly-overview-settings'


