import React from 'react'

const AddEmployeeWhite = ({color}) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="90.004" height="87.661" viewBox="0 0 90.004 87.661">
        <path id="Create_new_employee" data-name="Create new employee" d="M59.687,72.524A15.159,15.159,0,1,1,74.846,87.661,15.148,15.148,0,0,1,59.687,72.524Zm7.579,1.514h6.063v6.055h3.032V74.038h6.063V71.01H76.362V64.955H73.329V71.01H67.266ZM60.64,82.93H0V72.563A33.715,33.715,0,0,1,4.015,55.785a26.15,26.15,0,0,1,11.2-10.625,30.825,30.825,0,0,0,45.683,0,26.229,26.229,0,0,1,10.611,9.621A17.5,17.5,0,0,0,60.64,82.928l0,0ZM28.891,46.418a24.291,24.291,0,1,1,9.416,1.9A24.123,24.123,0,0,1,28.891,46.418Z" fill={color} />
      </svg>

    </>
  )
}

export default AddEmployeeWhite