import React, { useEffect, useState } from "react";
import FormsNew from "../molecules/FormsNew";
import { t } from "../../translations/Translation";
import { GetFormattedDate, GetListFromArray, debounce } from "../../utilities/CommonFunctions";
import Table from "../atoms/Table";
import BackIcon from "../../static/icons/BackIcon.png"
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetDimonaApiUrl, GetDimonaDetailsApiUrl, ForceProcessDimonaApiUrl, ResendDimonaApiUrl, CancelDimonaApiUrl, GetDimonaDeclatrationDetailsApiUrl, ResendDimonaDeclarationDetailsApiUrl, GetDimonaConnectionStatusApiUrl } from "../../routes/ApiEndPoints";
import DimonaSuccessIcon from "../../static/icons/DimonaSuccess.svg";
import DimonaFailedIcon from "../../static/icons/DimonaFail.svg";
import DimonaPendingIcon from "../../static/icons/DimonaPending.svg";
import DimonaWarningIcon from "../../static/icons/DimonaWarning.svg";
import { ToastContainer, toast } from 'react-toastify';
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import ModalPopup from "../../utilities/popup/Popup";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CustomPopup from "../../utilities/popup/CustomPopup";
import CustomJsonEditor from "../atoms/CustomJsonEditor"
import CustomButton from "../atoms/CustomButton";


export default function DimonaOverview() {

    const [typeList, setTypeList] = useState([{ value: 'all', label: 'All' }, { value: 'plan', label: 'Plan' }, { value: 'real_time', label: 'Real time' }, { value: 'daily_registration', label: 'Daily registration' }, { value: 'flex_check', label: 'Flex check' }, { value: 'long_term', label: 'Long term' }]);
    const [selectedType, setSelectedType] = useState({ value: 'all', label: 'All' });
    const [manageStatus, setManageStatus] = useState(true);
    const [manageListData, setManageListData] = useState([]);
    const [detailsListData, setDetailsListData] = useState({});
    const [detailsRows, setDetailsRows] = useState([]);
    const [errors, setErrors] = useState([]);

    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];

    let date_obj = new Date()
    let currentDate = GetFormattedDate(date_obj, date_obj.getFullYear())
    const [formattedData, setFormattedData] = useState({
        "from_date": currentDate,
        "to_date": currentDate,
        "type": 'all'
    })
    const [warningMessage, setWarningMessage] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [apiUrl, setApiUrl] = useState("")
    const [dimonaData, setDimonaData] = useState({});
    const [action, setAction] = useState('');
    const [refresh, setRefresh] = useState(true)
    const [editJson, setEditJson] = useState(false)
    const [jsonData, setJsonData] = useState('')
    const [dimonaDeclarationId, setDimonaDeclarationId] = useState();
    const [dimonaId, setDimonaId] = useState('')

    const [processPopup, setProcessPopup] = useState(false)
    const [dimonaConnectionStatus, setDimonaConnectionStatus] = useState({})

    const manage_header = [
        // {
        //     title: t("PLAN_DATE"),
        //     field: "plan_date",
        //     status: "200",
        // },
        {
            title: t("EMPLOYEE_NAME"),
            field: "name",
            status: "200",
        },
        {
            title: t("DIMONA_TYPE"),
            field: "type",
            status: "200",
        },
        {
            title: t("DIMONA_DATE"),
            field: "dimona_date",
            status: "200",
        },
        // {
        //     title: t("WORKING_TOO_LONG") + ' ?',
        //     field: "overtime",
        //     status: "200",
        // },
        {
            title: t("EMPLOYEE_TYPE"),
            field: "employee_type",
            status: "200",
        },
        {
            title: t("DIMONA_PERIOD_ID"),
            field: "dimona_period_id",
            status: "200",
        },
        {
            title: t("DIMONA_STATUS"),
            field: "dimona_status",
            status: "200",
        },
    ]

    const detail_header = [
        {
            title: t("DECLARATION_TYPE"),
            field: "declaration_type",
            status: "200",
        },
        {
            title: t("START_TEXT"),
            field: "start",
            status: "200",
        },
        // {
        //     title: t("EMPLOYEE_TYPE"),
        //     field: "employee_type",
        //     status: "200",
        // },
        {
            title: t("STOP_TEXT"),
            field: "stop",
            status: "200",
        },
        {
            title: t("HOURS"),
            field: "hours",
            status: "200",
        },
        // {
        //     title: t("SHORT_BREAK"),
        //     field: "short_break",
        //     status: "200",
        // },
        // {
        //     title: t("ERROR_CODE"),
        //     field: "error_code",
        //     status: "200",
        // },
        {
            title: t("DIMONA_STATUS"),
            field: "dimona_status",
            status: "200",
            render: rowData => (
                <><img alt={t("DIMONA_STATUS")} data-tooltip-id={'dimona' + rowData?.id} src={rowData.dimona_status === 'success' ? DimonaSuccessIcon : rowData.dimona_status === 'pending' ? DimonaPendingIcon : rowData.dimona_status === 'warning' ? DimonaWarningIcon : DimonaFailedIcon}></img>
                    <ReactTooltip
                        id={'dimona' + rowData.id}
                        place="top"
                        globalEventOff="click"
                        content={rowData.errors.length > 1 ? GetListFromArray(rowData.errors) : rowData.errors}
                        style={{ zIndex: 999 }}
                    />
                </>
            ),
        },
        {
            title: t("SENT_BY"),
            field: "sent_by",
            status: "200",
        },
        {
            title: t("SENT_AT"),
            field: "sent_at",
            status: "200",
        },
        // {
        //     title: t("REASON"),
        //     field: "reason",
        //     status: "200",
        // },
    ]

    useEffect(() => {
        AXIOS.service(GetDimonaApiUrl, 'POST', formattedData)
            .then((result) => {
                if (result?.status || result?.success) {
                    // let arr = []
                    // result.data.map((val, i) => {
                    //     val['id'] = i
                    //     arr.push(val)
                    // })
                    setManageListData(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [formattedData])

    useEffect(() => {
        setRefresh(true)
    }, [refresh])


    const filterData = [
        { title: t("TYPE"), name: 'type', required: false, options: typeList, selectedOptions: selectedType, isMulti: false, type: 'dropdown', style: "col-md-3 float-left" },
        { title: t("FROM_DATE"), name: 'from_date', required: false, type: 'date', style: "col-md-3 float-left" },
        { title: t("TO_DATE"), name: 'to_date', required: false, type: 'date', style: "col-md-3 float-left" },
    ]

    const setValues = (index, name, value, field) => {
        let dimona_data = { ...formattedData }
        dimona_data[name] = value
        if (field !== 'dropdown') {
            dimona_data[name] = value
        } else {
            setSelectedType(value)
            dimona_data[name] = value.value
        }
        setFormattedData(dimona_data);
    }

    const apiCall = () => {

        AXIOS.service(apiUrl, "GET")
            .then((result) => {
                if (result?.status || result?.success) {
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    if (action !== 'cancel dimona') {

                        viewAction(dimonaData, 'details')
                    }
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
        setWarningMessage('')
    }

    const getDimonaDeclarationData = (id) => {
        AXIOS.service(`${GetDimonaDeclatrationDetailsApiUrl}/${id}`, 'GET')
            .then((result) => {
                if (result?.status || result?.success) {
                    setJsonData(result?.data)
                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }).catch((error) => { console.log(error) });
    }

    const resendDimonaDdeclaration = () => {
        let data = {
            'dimona_declaration_id': dimonaDeclarationId,
            'declaration_data': jsonData
        }
        AXIOS.service(ResendDimonaDeclarationDetailsApiUrl, 'POST', data)
            .then((result) => {
                if (result?.status || result?.success) {
                    setEditJson(false)
                    setDimonaDeclarationId('')
                    setJsonData('')
                    setWarningMessage('')
                    getDimonaDetails(dimonaId)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setWarningMessage('')
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }).catch((error) => { console.log(error) });
    }


    const getDimonaDetails = (id) => {

        AXIOS.service(GetDimonaDetailsApiUrl + id, 'POST')
            .then((result) => {
                if (result?.status || result?.success) {
                    let arr = []
                    result.data?.declarations.map((val, i) => {
                        arr.push(val)
                    })
                    setDetailsRows(arr);
                    setDetailsListData(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const viewAction = (data, action) => {
        setAction(action)
        if (action === 'details') {
            setRefresh(!refresh)
            setDetailsRows([]);
            setManageStatus(false)
            setDimonaData(data)
            setDimonaId(data?.id)
            getDimonaDetails(data?.id)
        } else if (action === 'cancel dimona') {
            setPopupTitle(t("CANCEL_DIMONA"))
            setWarningMessage(t('ARE_YOU_SURE_WANT_TO_PROCEED'))
            setApiUrl(CancelDimonaApiUrl + "/" + data?.id)
        } else if (action === 'resend dimona') {
            setDimonaDeclarationId(data?.id)
            getDimonaDeclarationData(data?.id)
            if (data?.allow_resend_dimona) {
                setEditJson(true)
            }
        } else if (action === 'force process') {
            setPopupTitle(t("FORCE_PROCESS"))
            setWarningMessage(t('ARE_YOU_SURE_WANT_TO_PROCEED'))
            setApiUrl(ForceProcessDimonaApiUrl + "/" + data?.id)
        }
    }

    const onResend = () => {
        setPopupTitle(t("RESEND_DIMONA"))
        setWarningMessage(t('ARE_YOU_SURE_WANT_TO_PROCEED'))
    }

    const onEditorHide = () => {
        setEditJson(false)
        setDimonaDeclarationId('')
        setJsonData('')
    }

    const processApiCall = () => {
        AXIOS.service(GetDimonaConnectionStatusApiUrl, "GET")
            .then((result) => {
                if (result.success) {
                    setProcessPopup(true)
                    setDimonaConnectionStatus(result.data)
                } else {
                    setWarningMessage(result.message[0])
                }
            })
            .catch((error) => {
                console.log(error);

            })
    }

    //to avoide multiple api callls
    const handleClick = debounce((action === 'resend dimona' ? resendDimonaDdeclaration : apiCall), 1000)

    return (
        <div className="planning_body">
            {processPopup && <ModalPopup
                size="m"
                title={t("DIMONA_CONNECTION_STATUS")}
                body={<>
                    <div className="d-flex m-2">
                        <h5 id="text-indii-blue">{t("DECLARATION_ACCESS")}:</h5>
                        <span className={`ml-4  font-weight-bold  ${dimonaConnectionStatus?.request_status ? " text-green" : " text-red"}`}>{dimonaConnectionStatus?.request_status ? t("ACTIVE") : t("INACTIVE")}</span>
                    </div>
                    <div className="d-flex m-2">
                        <h5 id="text-indii-blue">{t("RESPONSE_ACCESS")}:</h5>
                        <span className={`ml-4 font-weight-bold ${dimonaConnectionStatus?.response_status ? " text-green" : " text-red"}`}>{dimonaConnectionStatus?.response_status ? t("ACTIVE") : t("INACTIVE")}</span>
                    </div>
                </>}
                onHide={() => { setProcessPopup(false); setDimonaConnectionStatus({}) }}
            ></ModalPopup>}
            {warningMessage && <ModalPopup
                title={popupTitle}
                body={(warningMessage)}
                onConfirm={handleClick}
                onHide={() => setWarningMessage('')}
            ></ModalPopup>}
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            {!manageStatus && <h4 className="pt-2 mb-0 px-2 bg-white"><img className="shortcut-icon mr-2 mb-1" onClick={() => setManageStatus(true)} src={BackIcon} alt={t("BACK_ICON")}></img>
                {t('DETAILED_OVERVIEW')}</h4>}
            <div className="bg-white my-1">
                {manageStatus && <div className="d-flex justify-content-start align-items-center"><FormsNew
                    classname={'col-9'}
                    view="filters"
                    formTitle={''}
                    formattedData={formattedData}
                    data={filterData}
                    SetValues={setValues}
                ></FormsNew>
                    <div className="h-25 col-2 d-flex justify-content-end" >
                        <CustomButton buttonName={t("CONNECTION_STATUS")} ActionFunction={() => processApiCall()}></CustomButton>
                    </div>
                </div>}

                {!manageStatus && <div className="col-md-12 py-3">
                    <div className="col-md-3 row m-0">
                        <label className="mb-0 font-weight-bold">{t("EMPLOYEE_NAME") + ':'}</label>
                        <p className="pl-2 mb-0">{detailsListData?.name}</p>
                    </div>
                    <div className="col-md-2 row mt-2 m-0">
                        <label className="mb-0 font-weight-bold">{t("WORKED_DATE") + ':'}</label>
                        <p className="pl-2 mb-0">{detailsListData?.dimona_sent_date}</p>
                    </div>
                    {/*
                    <div className="col-md-2 row mt-2 m-0">
                        <label className="mb-0 font-weight-bold">{t("PLANNED_DATE") + ':'}</label>
                        <p className="pl-2 mb-0">{'18-01-2024'}</p>
                    </div>
                    <div className="col-md-3 row mt-2 m-0">
                        <label className="mb-0 font-weight-bold">{t("PLANNED_PERIOD") + ':'}</label>
                        <p className="pl-2 mb-0">{'10:30 to 16:00'}</p>
                    </div> */}
                </div>}
            </div>
            <div className="bg-white mt-1">
                {editJson && <CustomPopup footer={jsonData ? true : false} size='xl' saveButtonName={t("RESEND_DIMONA")} onConfirm={() => onResend()} onHide={() => onEditorHide()}
                    body={
                        (jsonData ?
                            <div className="col-12">
                                <CustomJsonEditor data={jsonData} outPutFunction={(val) => { setJsonData(val) }} />
                            </div> : <div className="d-flex justify-content-center align-items-center">
                                <div className="spinner-border custom-loading-icon"></div></div>)
                    }></CustomPopup>}
                {refresh && <Table columns={manageStatus ? manage_header : detail_header} rows={manageStatus ? manageListData : detailsRows} tableName={manageStatus ? 'dimona_overview' : 'no_action_dimona'} viewAction={viewAction} permission={UserPermissions?.includes('dimona_overview_actions')} viewPermission={UserPermissions?.includes('dimona_overview_actions')}></Table>}
            </div>
        </div>
    )
}
