import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { t } from '../../translations/Translation';
import "./popup.css";


const CustomPopup = ({ children, ...props }) => {

    return (
        <Modal
            show={true}
            onHide={props.onHide}
            size={props.size ? props.size : "sm"}
            className=""
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={props.backdrop}
        >
            {props.header && <Modal.Header closeButton={props.close} className='bg-transparent'>
                <Modal.Title id="contained-modal-title-vcenter">
                    {(props.title)}
                </Modal.Title>
            </Modal.Header>}
            <Modal.Body>
                {props.disableSaveButton && props.loadingIcon && <div className="d-flex justify-content-center align-items-center popup-loading-icon-position"><div className="spinner-border custom-loading-icon"></div></div>}
                {props.body}
                {children}
            </Modal.Body>
            {props.footer && <Modal.Footer>
                {props.deleteTableRows && <Button className='button-style float-left' onClick={() => props.deleteTableRows(0, 0, 1, 0)}>
                    {t('YES_DELETE')}
                </Button>}
                {props.onConfirm && <Button className='button-style float-left' onClick={() => props.onConfirm()} disabled={props.disableSaveButton}>
                    {props.startplanButton ? props.startplanButton : (props.saveButtonName ? props.saveButtonName : t('YES'))}
                </Button>}
                {(props.title !== t("RESPONSIBLE_COMPANY") || props.title !== t("PLAN_DETAILS")) && (props.title !== t('UPDATE_OVERTIME_COUNTER')) && <Button className='button-style' onClick={props.onHide}>
                    {props.buttonName ? (props.buttonName) : t('CLOSE')}
                </Button>}
            </Modal.Footer>}
        </Modal>
    );
}

export default CustomPopup
