import ModalPopup from "../../utilities/popup/Popup";
import { t } from "../../translations/Translation"
import Table from "../atoms/Table";
import { useCallback, useEffect, useState } from "react";
import { GetEmployeeDayPlanningsApiUrl, GetEmployeesDayAvailabilityApiUrl } from "../../routes/ApiEndPoints";
import { APICALL } from "../../services/AxiosServices";
import CreatePlanPopup from "./CreatePlanPopup";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { SwitchEmployeeRequestOverviewPopup } from "./SwitchEmployeeRequestOverviewPopup";
import Dropdown from "../atoms/Dropdown";
import { Button } from "react-bootstrap";
import CustomButton from "../atoms/CustomButton";
import AddLeaveForPlanPopup from "./AddLeaveForPlanPopup";
import FormsNew from "./FormsNew";
import { GetFormattedDate } from "../../utilities/CommonFunctions";


export default function EmployeesAvailabilityPopup({ setDisplayAvailabilityPopup, date, setDayDate, tabIndex,  setDateFromPicker, locId, workstationOptions = [], dataRefresh, setDataRefresh }) {
    const [employeesList, setEmployeesList] = useState([])
    const [showPlanningPopup, setShowPlanningPopup] = useState(false)
    const [EID, setEID] = useState("")
    const [selectedWorkStation, setSelectedWorkStation] = useState(false)
    const [showWorkstationDropdown, setShowWorkstationDropdown] = useState(false)

    const [planData, setPlanData] = useState([])
    const [dropDownData, setDropDownData] = useState([])
    const [leavePopup, setLeavePopup] = useState(false)
    const [planIdForLeave, setPlanIdForLeave] = useState("")
    const [formData, setFormData] = useState({
        'date': date
    })

    const currentDate = new Date();

    const headers = [
        {
            title: t("NAME_TEXT"),
            field: 'name',
            size: 200,
        },
        {
            title: t("AVAILABILITY"),
            field: 'availability',
            size: 200,
        },
        {
            title: t("REMARKS"),
            field: 'remarks',
            size: 200,
            render: rows => <><p data-tooltip-id={rows?.remarks?.substring(0, 100)} data-tooltip-content={rows?.remarks}>{rows?.remarks ? `${rows?.remarks?.substring(0, 40)}...` : ""}</p> <ReactTooltip
                id={rows?.remarks?.substring(0, 100)}
                place="top"
                globalEventOff="click"
                render={(content, activeAnchor) => <div className="wrap-text">{content?.content}</div>}
            >
            </ReactTooltip>

            </>
        }
    ]

    useEffect(() => {

        APICALL.service(GetEmployeesDayAvailabilityApiUrl, "POST", { "date": date })
            .then((result) => {
                if (result.success) {
                    let newData = result.data.map((val, i) => {
                        return { ...val, id: val.employee_profile_id }
                    })
                    setEmployeesList(newData)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [showPlanningPopup, date])


    const viewAction = (data, action) => {
        if (action === 'add plan') {
            setEID((prev) => {
                // setShowPlanningPopup(!showPlanningPopup)
                setShowWorkstationDropdown(true)
                return data.employee_profile_id
            })
        }

    }

    const setValues = (index, name, value, field) => {
        let newData = { ...formData }
        if (field !== 'dropdown') {
            newData[name] = value
            setDateFromPicker(value)
        }
        setFormData(newData);
    }

    const getPlanningsData = () => {
        let data = {
            "location": locId,
            "workstation_id": selectedWorkStation?.value || "",
            "date": date,
            "employee_profile_id": EID
        }
        APICALL.service(GetEmployeeDayPlanningsApiUrl, 'POST', data)
            .then((result) => {
                if (result.success) {
                    setPlanData((prev) => {
                        setShowPlanningPopup(!showPlanningPopup)
                        let ddoptions = {
                            employee_type: result.data.employee_type,
                            function: result.data.function,
                        }
                        setDropDownData(ddoptions)
                        let plans = result.data?.planning
                        plans?.map((val, i) => {
                            val.start_time = val.timings.split(" ")[0]
                            val.end_time = val.timings.split(" ")[1]
                        })
                        return plans || []
                    })
                }
            })
    }
    const filterData = [
        { title: t("DATE"), name: 'date', required: false, type: 'date', style: "col-md-3 float-left" },
    ]
    return (
        <>
            {!showPlanningPopup && !leavePopup && <ModalPopup
                title={t("EMPLOYEES_AVAILABILITY")}
                size="xl"
                body={
                    <div className="container">
                        <div className="row d-flex">
                            {!showWorkstationDropdown && <div className={"col-12"}>
                                <div className="bg-white mt-1">
                                    <FormsNew
                                        view="filters"
                                        formTitle={''}
                                        formattedData={formData}
                                        data={filterData}
                                        SetValues={setValues}
                                    ></FormsNew>
                                </div>
                                <Table tableName={'availability_list'} columns={headers} rows={employeesList} viewAction={viewAction} permission={true}></Table>
                            </div>}
                            {showWorkstationDropdown && <div className="col-12 d-flex">
                                <Dropdown
                                    options={workstationOptions}
                                    selectedOptions={selectedWorkStation}
                                    onSelectFunction={(e) => setSelectedWorkStation(e)}
                                    CustomStyle={"m-4 col-8"}
                                    title={t("SELECTWORKSTATION")}
                                    required={true}
                                    isMulti={false}
                                    isClearable={true}
                                ></Dropdown>
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className=" h-25 p-2">
                                        <CustomButton ActionFunction={() => getPlanningsData()} buttonName={t('CREATE_PLAN')} CustomStyle={{ 'height': '20px' }}></CustomButton>
                                        <CustomButton ActionFunction={() => setShowWorkstationDropdown(false)} buttonName={"back"} CustomStyle={{ 'height': '20px' }}></CustomButton>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                }
                onHide={() => showPlanningPopup ? setShowPlanningPopup(!showPlanningPopup) :(tabIndex!==2?(setDisplayAvailabilityPopup(false), setDayDate(GetFormattedDate(currentDate, currentDate.getFullYear()))):setDisplayAvailabilityPopup(false))}
                close
            ></ModalPopup>}
            {showPlanningPopup && <CreatePlanPopup setPlanPopup={setShowPlanningPopup} wid={selectedWorkStation?.value} enableShift={false} employeeId={EID} planningDate={date} locId={locId} planData={planData} dropDownData={dropDownData} updatePlan={false} dataRefresh={dataRefresh} setDataRefresh={setDataRefresh} setLeavePopup={setLeavePopup} setPlanIdForLeave={setPlanIdForLeave} workstationOptions={workstationOptions} setShowWorkstationDropdown={setShowWorkstationDropdown} view="employees_availability" setDisplayAvailabilityPopup={setDisplayAvailabilityPopup}></CreatePlanPopup>}
            {leavePopup && <AddLeaveForPlanPopup leavePopup={leavePopup} setLeavePopup={setLeavePopup} setPlanPopup={setShowPlanningPopup} planIdForLeave={planIdForLeave} setPlanIdForLeave={setPlanIdForLeave} dataRefresh={dataRefresh} setDataRefresh={setDataRefresh} setShowWorkstationDropdown={setShowWorkstationDropdown} setDisplayAvailabilityPopup={setDisplayAvailabilityPopup} view="employees_availability"></AddLeaveForPlanPopup>}
        </ >

    )
}