import React from 'react'

const PlanningWhite = ({color}) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="93.506" height="87.661" viewBox="0 0 93.506 87.661">
        <g id="Planning" transform="translate(-2 -2)">
          <path id="Path_2" data-name="Path 2" d="M90.392,6H78.7v8.766a6.428,6.428,0,0,1-12.857,0V6H31.805v8.766a6.428,6.428,0,0,1-12.857,0V6H7.26A5.2,5.2,0,0,0,2,11.289V76.684a5.2,5.2,0,0,0,5.114,5.289H90.392a5.2,5.2,0,0,0,5.114-5.289V11.289A5.2,5.2,0,0,0,90.392,6ZM25.377,64.441H19.533V58.6h5.844Zm0-14.61H19.533V43.986h5.844Zm0-14.61H19.533V29.376h5.844Zm17.532,29.22H37.065V58.6h5.844Zm0-14.61H37.065V43.986h5.844Zm0-14.61H37.065V29.376h5.844Zm17.532,29.22H54.6V58.6h5.844Zm0-14.61H54.6V43.986h5.844Zm0-14.61H54.6V29.376h5.844Zm17.532,29.22H72.129V58.6h5.844Zm0-14.61H72.129V43.986h5.844Zm0-14.61H72.129V29.376h5.844Z" transform="translate(0 7.688)" fill={color} />
          <path id="Path_3" data-name="Path 3" d="M11.922,25.376a2.922,2.922,0,0,0,2.922-2.922V4.922A2.922,2.922,0,1,0,9,4.922V22.454A2.922,2.922,0,0,0,11.922,25.376Z" transform="translate(13.455 0)" fill={color} />
          <path id="Path_4" data-name="Path 4" d="M27.922,25.376a2.922,2.922,0,0,0,2.922-2.922V4.922a2.922,2.922,0,1,0-5.844,0V22.454A2.922,2.922,0,0,0,27.922,25.376Z" transform="translate(44.207 0)" fill={color} />
        </g>
      </svg>
    </>
  )
}

export default PlanningWhite