import React, { useEffect, useState } from "react";
import EyeIcon from "../../static/icons/Eye.png"
import EditIcon from "../../static/icons/Edit.svg"
import DeleteIcon from "../../static/icons/Delete.svg"
import LinkIcon from "../../static/icons/LinkingSocialSecretaryToHolidayCode.svg"
import MoreIcon from "../../static/icons/menu.png"
import AcceptIcon from "../../static/icons/Available.svg"
import RejectIcon from "../../static/icons/Notavailable.svg"
import MoveIcon from "../../static/icons/Move.png"
import MaterialTable from "material-table";
import { ArrowUpward, ChevronRight, NavigateNextRounded, NavigateBeforeRounded, RotateLeft, Search, Edit, Done, Clear } from "@material-ui/icons";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { t } from "../../translations/Translation";
// import CustomButton from "./CustomButton";
import Archive from "../../static/icons/archive.png";
import Unarchive from "../../static/icons/unarchive.png";
import ResendDimonaIcon from "../../static/icons/forward.png";
import CancelDimonaIcon from "../../static/icons/CancelDimona.svg";
import RefreshDimonaIcon from "../../static/icons/refresh.png";
import SelectIcon from "../../static/icons/Select.svg";
import MergeIcon from "../../static/icons/MergeIcon.svg";
import BlockUserIcon from "../../static/icons/BlockUser.svg";
import UnblockUserIcon from "../../static/icons/UnblockUser.svg";
import RightArrowIcon from "../../static/icons/right-arrow.png";

export default function Table({ columns, rows, tableName, showDetails, viewAction, empId, parentId, height, setRows, SaveSalaries, multiPurpose, setDimonaData, isArchived, permission, viewPermission, deletePermission, style, tableRef, filters }) {
    // const [rowData, setRowData] = useState(rows);

    //Theme added for table
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#4caf50',
            },
            secondary: {
                main: '#ff9100',
            },
        }
    })
    const [pageSize, setPageSize] = useState(parseInt(localStorage.getItem('pageSize'), 10) || 10);

    // Retrieve saved pageSize from localStorage on mount
    useEffect(() => {
        const savedPageSize = localStorage.getItem('pageSize');
        if (savedPageSize) {
            setPageSize(parseInt(savedPageSize, 10));
        }
    }, []);

    // Save pageSize to localStorage whenever it changes
    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        localStorage.setItem('pageSize', pageSize);
    };

    //All the icons used in the table are defined below
    const tableIcon = {
        Search: Search,
        ResetSearch: RotateLeft,
        SortArrow: ArrowUpward,
        DetailPanel: ChevronRight,
        NextPage: NavigateNextRounded,
        PreviousPage: NavigateBeforeRounded,
        Edit: Edit,
        Check: Done,
        Clear: Clear,
    }

    // const searchStyle = showDetails ? { width: '' } : { width: '200%', border: '0.5px solid #ABABAB', borderRadius: '5px' }

    const searchStyle = showDetails || tableName === 'send_dimona' ? { width: '' } : { width: '200%' }


    //Table options
    const options = {
        filtering: false,
        maxBodyHeight: showDetails ? 'calc(100vh - 222px)' : multiPurpose ? 'calc(100vh - 308px)' : tableName !== 'employee' && tableName !== "open_shifts_overview" ? '100%' : 'calc(100vh - 220px)', //'83.5vh',
        // maxBodyHeight: showDetails ? 'calc(100vh - 222px)' : tableName !== 'employee' ? 'calc(100vh - 264px)': multiPurpose ? 'calc(100vh - 300px)' // Set your custom height here
        //     : 'calc(100vh - 264px)'
        //   : 'calc(100vh - 220px)',
        selection: (tableName === 'send_dimona' || tableName === 'events' || tableName == 'bulk_approve_plan_end_time' ? true : false),

        //Search toolbar props
        toolbar: (tableName !== 'tokens' && tableName !== 'no_action_dimona' && tableName !== 'current_plans') ? true : false,
        search: tableName !== 'min_salary' && tableName !== 'no_action_dimona' ? true : false,
        searchFieldAlignment: 'left',
        searchFieldStyle: searchStyle, //padding: '0px',

        //Expand props (Parent and child format/tree data format)
        expanded: true,
        defaultExpanded: (rowData) => (
            showDetails && rowData.id !== parentId ? false : true
        ),

        //Header style props
        headerStyle: {
            backgroundColor: "#61BFB5",
            color: "#FFF",
            fontSize: "0.875rem",
            textAlign: 'left',
            fontWeight: "bold",
            zIndex: 1,
            display: showDetails ? 'none' : '',
            position: 'sticky',
            top: 0
        },

        //Pagination props
        paging: tableName === 'employee' || tableName === 'min_salary' || tableName === 'tokens' || tableName === 'open_shifts_overview' || tableName === 'send_dimona' || tableName === 'events' || tableName === 'current_plans' ? false : true,
        pageSize: pageSize,
        pageSizeOptions: [5, 10, 50],
        emptyRowsWhenPaging: false,
        showFirstLastPageButtons: false,

        //Row style props
        rowStyle: (rowData) => ({
            backgroundColor: rowData.parentOnly ? "#2929291A" : rowData.id === empId && showDetails ? "rgb(146 233 225 / 22%)" : "#FFFFFF",
            fontSize: "0.875rem",
        }),
        addRowPosition: "first",

        //Actions props
        actionsCellStyle: { width: '100px', padding: '0px 20px' },
        actionsColumnIndex: -1,
    }


    const getViewIcon = () => { return (<img className="shortcut-icon" src={EyeIcon} alt="view"></img>) }
    const getEditIcon = () => { return (<img className="header-icon " src={EditIcon} alt="edit"></img>) }
    const getDeleteIcon = () => { return (<img className="header-icon " src={DeleteIcon} alt="delete"></img>) }
    const getLinkIcon = () => { return (<img className="planning-icon " src={LinkIcon} alt="link"></img>) }
    const getDetailIcon = () => { return (<img className="planning-icon" src={MoreIcon} alt="detail"></img>) }
    const getAcceptIcon = () => { return (<img className="planning-icon" src={AcceptIcon} alt="accept"></img>) }
    const getRejectIcon = () => { return (<img className="planning-icon" src={RejectIcon} alt="reject"></img>) }
    const getArchiveIcon = () => { return (<img className="planning-icon" src={Archive} alt="archive"></img>) }
    const getUnarchiveIcon = () => { return (<img className="planning-icon" src={Unarchive} alt="unarchive"></img>) }
    const getMoveIcon = () => { return (<img className="planning-icon" src={MoveIcon} alt="move"></img>) }
    const getCancelDimonaIcon = () => { return (<img className="planning-icon" src={CancelDimonaIcon} alt="move"></img>) }
    const getForceProcessIcon = () => { return (<img className="planning-icon" src={RefreshDimonaIcon} alt="move"></img>) }
    const getResendDimonaIcon = () => { return (<img className="planning-icon-forword" src={ResendDimonaIcon} alt="move"></img>) }
    const getSelectIcon = () => { return (<img className="planning-icon" src={SelectIcon} alt="select"></img>) }
    const getMergeIcon = () => { return (<img className="planning-icon" src={MergeIcon} alt="merge"></img>) }
    const getBlockIcon = () => { return (<img className="planning-icon" src={BlockUserIcon} alt="block"></img>) }
    const getUnBlockIcon = () => { return (<img className="planning-icon" src={UnblockUserIcon} alt="unblock"></img>) }
    const getRightArrowIcon = () => { return (<img className="planning-icon" src={RightArrowIcon} alt="right-arrow"></img>) }


    const actionsList = [
        rowData => ({
            icon: () => getMoveIcon(),
            tooltip: t("Move"),
            onClick: (event, rowData) => viewAction(rowData, 'move'),
            hidden: false
        }),
        rowData => ({
            icon: () => getDeleteIcon(),
            tooltip: t("DELETE"),
            onClick: (event, rowData) => viewAction(rowData, 'delete'),
            hidden: false
        }),
    ]

    //Define actions based on requirement (Below actions are for view and edit)
    const actionIconsList = [
        rowData => ({
            icon: () => getDetailIcon(),
            tooltip: t("DETAILS"),
            onClick: (event, rowData) => viewAction(rowData, 'details'),
            hidden: (!rowData.parentOnly && tableName !== 'location' && tableName !== 'workstation' && tableName !== 'social_secretary_engines' && tableName !== 'responsible_person' && tableName !== 'function' && tableName !== 'social_secretary' && tableName !== 'cost center' && tableName !== "email_template" && tableName !== 'contracts' && tableName !== "contract_template" && tableName !== 'employee' && tableName !== "open_shifts_overview" && tableName !== "open_shifts_templates" && tableName !== "applied_candidates" && tableName !== "approved_candidates" && tableName !== "rejected_candidates" && tableName !== 'holiday_overview' && tableName !== 'rules' && tableName !== 'documents_overview' && tableName !== 'taxes' && tableName !== 'no_action_dimona' && tableName !== 'invite_employee' && tableName !== 'manage_employees' && tableName !== 'employee_company_details' && tableName !== 'notification_template') ? (viewPermission ? false : true) : true
        }),
        rowData => ({
            icon: () => getViewIcon(),
            tooltip: t("VIEW"),
            onClick: (event, rowData) => viewAction(rowData, 'view'),
            hidden: (!rowData.parentOnly && tableName !== 'dimona_overview' && tableName !== 'invite_employee' && tableName !== 'location' && tableName !== 'social_secretary_engines' && tableName !== 'workstation' && tableName !== 'responsible_person' && tableName !== 'function' && tableName !== 'social_secretary' && tableName !== 'cost center' && tableName !== "email_template" && tableName !== "contract_template" && tableName !== 'contracts' && tableName !== 'holiday_overview' && tableName !== "applied_candidates" && tableName !== "approved_candidates" && tableName !== "rejected_candidates" && tableName !== 'rules' && tableName !== 'taxes' && tableName !== 'no_action_dimona' && tableName !== "open_shifts_templates" && tableName !== 'roles' && tableName !== 'overtime_counter' && tableName !== 'edit_and_approve_plan_end_time' && tableName !== 'bulk_approve_plan_end_time' && tableName !== 'approved_late_plans' && tableName !== 'employee_company_details' && tableName !== 'notification_template' && tableName !== 'switch_employee' && tableName !=='availability_list') ? (permission || viewPermission ? false : true) : true
        }),
        rowData => ({
            icon: () => getLinkIcon(),
            tooltip: t("SOCIAL_SECRETARY_CODE"),
            onClick: (event, rowData) => viewAction(rowData, 'link'),
            hidden: (tableName === 'social_secretary') ? (permission ? false : true) : true
        }),
        rowData => ({
            icon: () => getSelectIcon(),
            tooltip: t('USE_TEMPLATE'),
            onClick: (event, rowData) => viewAction(rowData, 'use_template'),
            hidden: (!rowData.parentOnly && tableName === 'open_shifts_templates') ? (permission ? false : true) : true
        }),

        rowData => ({
            icon: () => getMergeIcon(),
            tooltip: t('MERGE'),
            onClick: (event, rowData) => viewAction(rowData, 'merge'),
            hidden: (!rowData.parentOnly && tableName === 'manage_employees') ? (permission ? false : true) : true
        }),

        rowData => ({
            icon: () => getEditIcon(),
            tooltip: t("EDIT"),
            onClick: (event, rowData) => viewAction(rowData, 'edit'),
            hidden: (!rowData.parentOnly && tableName !== 'employee' && tableName !== 'dimona_overview' && tableName !== 'invite_employee' && tableName !== 'holiday_overview' && tableName !== "applied_candidates" && tableName !== "approved_candidates" && tableName !== "rejected_candidates" && tableName !== 'documents_overview' && tableName !== 'no_action_dimona' && tableName !== 'bulk_approve_plan_end_time' && tableName !== 'manage_employees' && tableName !== 'employee_company_details' && tableName !== 'switch_employee' && tableName !=='availability_list' && (tableName === 'roles' ? !rowData.is_builtin : true)) ? (permission ? false : true) : true
        }),

        rowData => ({
            icon: () => getBlockIcon(),
            tooltip: t('BLOCK'),
            onClick: (event, rowData) => viewAction(rowData, 'block'),
            hidden: (!rowData.parentOnly && (tableName === 'manage_employees' && !rowData.is_blocked)) ? (permission ? false : true) : true
        }),
        rowData => ({
            icon: () => getUnBlockIcon(),
            tooltip: t('UNBLOCK'),
            onClick: (event, rowData) => viewAction(rowData, 'unblock'),
            hidden: (!rowData.parentOnly && (tableName === 'manage_employees' && rowData.is_blocked)) ? (permission ? false : true) : true
        }),
        rowData => ({
            icon: () => getDeleteIcon(),
            tooltip: t("DELETE"),
            onClick: (event, rowData) => viewAction(rowData, 'delete'),
            hidden: (!rowData.parentOnly && tableName !== "email_template" && tableName !== 'dimona_overview' && tableName !== 'invite_employee' && tableName !== 'social_secretary_engines' && tableName !== 'holiday_overview' && tableName !== "applied_candidates" && tableName !== "approved_candidates" && tableName !== "rejected_candidates" && tableName !== 'rules' && tableName !== 'company' && tableName !== 'employee' && tableName !== 'no_action_dimona' && tableName !== 'edit_and_approve_plan_end_time' && tableName !== 'bulk_approve_plan_end_time' && tableName !== 'approved_late_plans' && tableName !== 'manage_employees' && (tableName === 'roles' ? !rowData.is_builtin : true) && tableName !== 'overtime_counter') && tableName !== 'switch_employee' && tableName !== 'notification_template' && tableName !=='availability_list' ? (permission ? false : true) : true
        }),
        rowData => ({
            icon: () => getAcceptIcon(),
            tooltip: (tableName === 'edit_and_approve_plan_end_time' || tableName === 'bulk_approve_plan_end_time') ? t('APPROVE') : t('ACCEPT'),
            onClick: (event, rowData) => viewAction(rowData, (tableName === 'edit_and_approve_plan_end_time' || tableName === 'bulk_approve_plan_end_time') ? 'approve' : 'accept'),
            hidden: (!rowData.parentOnly && (tableName === "applied_candidates" || tableName === "rejected_candidates" || tableName === 'edit_and_approve_plan_end_time' || tableName === 'bulk_approve_plan_end_time' || tableName === 'switch_employee' || (tableName === 'invite_employee' && rowData.can_confirm))) ? (permission ? false : true) : true
        }),
        rowData => ({
            icon: () => getRejectIcon(),
            tooltip: t('REJECT'),
            onClick: (event, rowData) => viewAction(rowData, 'reject'),
            hidden: (!rowData.parentOnly && (tableName === "applied_candidates" || tableName === "approved_candidates" || tableName === 'switch_employee' || (tableName === 'invite_employee' && rowData.can_cancel))) ? (permission ? false : true) : true
        }),
        rowData => ({
            icon: () => getDetailIcon(),
            tooltip: t('ACTIONS'),
            onClick: (event, rowData) => viewAction(rowData, 'actions'),
            hidden: (!rowData.parentOnly && tableName === "holiday_overview" || tableName === "location") ? (permission ? false : true) : true
        }),
        // archieve
        rowData => ({
            icon: () => getArchiveIcon(),
            tooltip: t('ARCHIVE'),
            onClick: (event, rowData) => viewAction(rowData, 'archive'),
            hidden: (!rowData.parentOnly && tableName !== "email_template" && tableName !== 'dimona_overview' && tableName !== 'invite_employee' && tableName !== 'holiday_overview' && tableName !== "applied_candidates" && tableName !== 'rules' && tableName !== 'documents_overview' && (tableName === 'company' || tableName === 'employee') && !isArchived) ? (permission ? ((deletePermission && tableName === 'company' || tableName === 'employee') ? false : true) : true) : true
        }),
        // unarchieve
        rowData => ({
            icon: () => getUnarchiveIcon(),
            tooltip: t('UNARCHIVE'),
            onClick: (event, rowData) => viewAction(rowData, 'unarchive'),
            hidden: (!rowData.parentOnly && tableName !== "email_template" && tableName !== 'dimona_overview' && tableName !== 'invite_employee' && tableName !== 'holiday_overview' && tableName !== "applied_candidates" && tableName !== 'rules' && tableName !== 'documents_overview' && (tableName === 'company' || tableName === 'employee') && isArchived) ? (permission ? false : true) : true
        }),

        rowData => ({
            icon: () => getCancelDimonaIcon(),
            tooltip: t('CANCEL_DIMONA'),
            onClick: (event, rowData) => viewAction(rowData, 'cancel dimona'),
            hidden: (!rowData.parentOnly && tableName === 'dimona_overview' && tableName !== 'invite_employee' && rowData.allow_cancel) ? (permission ? false : true) : true
        }),
        rowData => ({
            icon: () => getForceProcessIcon(),
            tooltip: t('FORCE_PROCESS'),
            onClick: (event, rowData) => viewAction(rowData, 'force process'),
            hidden: (!rowData.parentOnly && tableName === 'no_action_dimona' && rowData.allow_force_process) ? (permission ? false : true) : true
        }),
        rowData => ({
            icon: () => getResendDimonaIcon(),
            tooltip: t('RESEND_DIMONA'),
            onClick: (event, rowData) => viewAction(rowData, 'resend dimona'),
            hidden: (!rowData.parentOnly && tableName === 'no_action_dimona' && rowData.allow_resend_dimona) ? (permission ? false : true) : true
        }),
        rowData => ({
            icon: () => getRightArrowIcon(),
            tooltip: tableName === 'availability_list'?t('ADD_PLAN'):t('PROCEED'),
            onClick: (event, rowData) => viewAction(rowData, 'add plan'),
            hidden: (!rowData.parentOnly && tableName === 'availability_list') ? (permission ? false : true) : true
        }),
    ]

    // const localization = {
    //     body: {
    //         emptyDataSourceMessage : 'Please select sector to get the salaries'
    //     }
    // }


    return (
        <MuiThemeProvider theme={theme}>
            <MaterialTable
                title=''
                style={style ? style : { width: "100%", height: tableName !== 'employee' || tableName !== 'open_shifts_overview' ? height : 'calc(100vh - 156px)' }}
                icons={tableIcon}
                columns={columns}
                data={rows}
                isLoading={rows ? false : true}
                tableRef={tableRef}
                onChangeRowsPerPage={(newPageSize) => handlePageSizeChange(newPageSize)}
                editable={tableName === 'min_salary' ? {
                    onBulkUpdate: (changes) => //newData, oldData
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const updatedData = [...rows];
                                // Apply changes to the updatedData array based on the "changes" object
                                for (const rowId in changes) {
                                    const rowIndex = updatedData.findIndex(
                                        (item) => item.id === changes[rowId].oldData.id
                                    );
                                    if (rowIndex !== -1) {
                                        updatedData[rowIndex] = {
                                            ...updatedData[rowIndex],
                                            ...changes[rowId].newData,
                                        };
                                    }
                                }

                                setRows(updatedData);
                                SaveSalaries(updatedData);
                                resolve();
                            }, 1000);
                        }),
                } : false}

                //Parent child format for employee overview
                parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}

                //Options for table modifications
                options={options}

                onSelectionChange={(rows) => setDimonaData(rows)}

                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} of {count}',
                        labelRowsSelect: t("ROWS"),
                    },
                    toolbar: {
                        nRowsSelected: '', // `${`0`} ${t("ROW")}(s) selected` ,
                        searchPlaceholder: t("SEARCH_TEXT"),
                    },
                    header: {
                        actions: t("ACTIONS")
                    },
                    body: {
                        emptyDataSourceMessage: t("NO_RECORDS_TO_DISPLAY"),
                        filterRow: {
                            filterTooltip: 'Filter'
                        }
                    }
                }}
                //Actions props
                actions={showDetails || tableName === 'min_salary' || tableName === 'tokens' || tableName === 'holiday_overview_rejected' || tableName === 'send_dimona' || tableName === 'import_overview' || tableName === 'planning_logs' || tableName === 'permissions' || tableName === 'current_plans' || tableName === 'notifications' || tableName === 'managers_list' ? [] : tableName === 'events' ? actionsList : actionIconsList}
            />
        </MuiThemeProvider>

    );
};
