import React from 'react'

const EmployeeAvailability = ({color}) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="89.578" height="87" viewBox="0 0 89.578 87">
        <path id="Employee_availability" data-name="Employee availability" d="M76.892,87a.441.441,0,0,1-.35-.267h0l-.731-1.751q-.619.1-1.248.151a15.582,15.582,0,0,1-6.173-.783,15.323,15.323,0,0,1-9.03-8.31,15.681,15.681,0,0,1-1.293-6.085A15.161,15.161,0,0,1,69.594,55.182a.422.422,0,0,1,.518.466.441.441,0,0,1-.291.347,13.469,13.469,0,0,0,4.615,26.114c.058,0,.117,0,.176,0l-.738-1.768a.441.441,0,0,1,.463-.606l6.869.884h0a.44.44,0,0,1,.294.7l-4.2,5.507a.44.44,0,0,1-.349.173A.383.383,0,0,1,76.892,87Zm7.033-7.116a2.3,2.3,0,1,1,.85.164A2.3,2.3,0,0,1,83.924,79.88Zm-25.79-.262H0V69.666A32.4,32.4,0,0,1,3.85,53.557a25.083,25.083,0,0,1,10.742-10.2,29.524,29.524,0,0,0,43.8,0A25.157,25.157,0,0,1,68.56,52.593,16.806,16.806,0,0,0,58.136,79.618h0Zm28.28-6a2.307,2.307,0,1,1,.853.164A2.308,2.308,0,0,1,86.415,73.614ZM72.571,73.2a.869.869,0,0,1-.559-.356,28.468,28.468,0,0,0-1.833-2.35,23.34,23.34,0,0,0-2.014-2.051.868.868,0,1,1,1.156-1.294,25.128,25.128,0,0,1,2.163,2.2q.622.715,1.209,1.487a37.456,37.456,0,0,1,7.894-8.1h0a.869.869,0,0,1,1.027,1.4,33.884,33.884,0,0,0-4.488,4.022,32.781,32.781,0,0,0-3.673,4.648s-.21.224-.233.24a.864.864,0,0,1-.5.157A.835.835,0,0,1,72.571,73.2Zm13.22-6.517a2.066,2.066,0,1,1,.764.147A2.068,2.068,0,0,1,85.792,66.683Zm-3.571-6.037a1.609,1.609,0,1,1,.595.114A1.61,1.61,0,0,1,82.221,60.646Zm-5.71-3.767a1.027,1.027,0,1,1,.379.073A1.016,1.016,0,0,1,76.511,56.88ZM27.7,44.564a23.255,23.255,0,1,1,9.027,1.824A23.1,23.1,0,0,1,27.7,44.564Z" fill={color} />
      </svg>

    </>
  )
}

export default EmployeeAvailability