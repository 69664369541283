import React, { useEffect, useState } from "react";
import { t } from "../../translations/Translation";
import Table from "../atoms/Table";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import ModalPopup from "../../utilities/popup/Popup";
import { toast } from "react-toastify";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import { GetRequestsForSwitchEmployeeApiUrl, ApproveRequestForSwitchEmployeeApiUrl, RejectRequestForSwitchEmployeeApiUrl } from "../../routes/ApiEndPoints";

export function SwitchEmployeeRequestOverviewPopup({ setShowEmployeeSwitchPopup, setDataRefresh, dataRefresh }) {

    const [listData, setListData] = useState([]);
    const [errors, setErrors] = useState([]);
    const [warningMessage, setWarningMessage] = useState("");
    const [apiurl, setApiUrl] = useState("");
    // const [dataRefresh, setDataRefresh] = useState(false)

    const headers = [
        {
            title: t("REQUEST_FROM"),
            field: 'request_from',
            size: 200,
        },
        {
            title: t("REQUEST_TO"),
            field: 'request_to',
            size: 200,
        },
        {
            title: t("LOCATION"),
            field: 'location_name',
            size: 200,
        },
        {
            title: t("WORKSTATION"),
            field: 'workstation_name',
            size: 200,
        },
        {
            title: t("PLAN_DATE"),
            field: 'plan_date',
            size: 200,
        },
        {
            title: t("PLAN_TIMINGS"),
            field: 'plan_timings',
            size: 200,
        }
    ]

    const viewAction = (data, action) => {
        // console.log(data, action);
        if (action === 'accept') {
            setWarningMessage(t('ARE_YOU_SURE_YOU_WANT_APPROVE') + " ?")
            setApiUrl(`${ApproveRequestForSwitchEmployeeApiUrl}/${data.id}`)
        } else {
            setWarningMessage(t('ARE_YOU_SURE_YOU_WANT_REJECT') + " ?")
            setApiUrl(`${RejectRequestForSwitchEmployeeApiUrl}/${data.id}`)
        }
    }

    const handleApproveOrReject = () => {
        AXIOS.service(apiurl, 'GET')
            .then((result) => {
                if (result?.success) {
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setDataRefresh(!dataRefresh)
                    setWarningMessage("")
                    setApiUrl("")
                } else {
                    setWarningMessage("")
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })

    }

    useEffect(() => {

        AXIOS.service(GetRequestsForSwitchEmployeeApiUrl, 'GET')
            .then((result) => {
                if (result?.success) {
                    setListData(result.data)
                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [dataRefresh])


    return (
        <ModalPopup
            size="xl"
            title={t("EMPLOYEE_SWITCH_REQUESTS")}
            body={
                <>
                    {warningMessage && <ModalPopup
                        title={t("WARNING_TITLE")}
                        body={(warningMessage)}
                        onConfirm={() => handleApproveOrReject()}
                        onHide={() => setWarningMessage('')}
                    ></ModalPopup>}
                    {errors !== undefined && errors.length !== 0 && <ErrorPopup
                        title={t("VALIDATION_ERROR") + ("!")}
                        body={(errors)}
                        onHide={() => setErrors([])}
                    ></ErrorPopup>}
                    <Table id={"switch_employee"} tableName={'switch_employee'} columns={headers} rows={listData} viewAction={viewAction} permission={true}></Table>
                </>
            }
            onHide={() => { setShowEmployeeSwitchPopup(false); setDataRefresh(!dataRefresh) }}
            close={true}
        >

        </ModalPopup>
    )
}