import react, { useEffect, useState } from 'react';
import CustomPopup from '../../utilities/popup/CustomPopup';
import { APICALL } from '../../services/AxiosServices';
import { GetMonthlyOverviewSettingsOptionApiUrl } from '../../routes/ApiEndPoints';
import { t } from "../../translations/Translation";
import Dropdown from '../atoms/Dropdown';
import ErrorPopup from '../../utilities/popup/ErrorPopup';
export const FilterSettingsOfMonthlyOverviewPopup = ({ filterPopup, setFilterPopup, OnSave }) => {

    const [selectedSubFilter, setSelectedSubFilter] = useState('')
    const [filterOptions, setFilterOptions] = useState([])
    const [errors, setErrors] = useState([]);

    useEffect(() => {

        APICALL.service(GetMonthlyOverviewSettingsOptionApiUrl, "GET")
            .then((result) => {
                if (result.status) {
                    setFilterOptions(result.data?.options?.monthly_overview_order_preference)
                    setSelectedSubFilter(result.data?.monthly_overview_order_preference)
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);

            })
    }, [])

    const onSortAction = () => {

        APICALL.service(GetMonthlyOverviewSettingsOptionApiUrl, "POST", { "monthly_overview_order_preference": selectedSubFilter?.value })
            .then((result) => {
                if (result.status) {
                    setFilterOptions(result.data?.options?.monthly_overview_order_preference)
                    OnSave()
                    setFilterPopup(false)
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);

            })

    }

    return (
        <>
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            <CustomPopup
                title={t("SORT_BY")}
                show={filterPopup}
                onHide={() => setFilterPopup(false)}
                header
                // child={}
                footer
                onConfirm={() => onSortAction()}
                saveButtonName={t("SAVE")}
            ><div>
                    <Dropdown
                        options={filterOptions}
                        selectedOptions={selectedSubFilter}
                        onSelectFunction={setSelectedSubFilter}
                        isClearable={true}
                    ></Dropdown>
                </div></CustomPopup>
        </>
    )
}