import React from "react";
import Card from "../components/atoms/Card";
// import ContractTypeIcon from "../static/icons/ContractType.svg";
// import EmployeeTypeIcon from "../static/icons/EmployeeType.svg";
// import SectorsIcon from "../static/icons/Sectors.svg";
// import FunctionsIcon from "../static/icons/Functions.svg";
// import GroupFunctionsIcon from "../static/icons/GroupFunctions.svg";
// import SalariesIcon from "../static/icons/Salaries.svg";
// import ReasonsIcon from "../static/icons/Reasons.svg";
// import SocialSecretaryIcon from "../static/icons/SocialSecretary.svg";
// import HolidayCodeIcon from "../static/icons/HolidayCode.svg";
// import Email from "../static/icons/EmailTemplate.svg";
// import TranslationIcon from "../static/icons/translate.svg";
import { t } from "../translations/Translation";
// import ParameterIcon from "../static/icons/term.png";
import Salaries from "../static/icons/Salaries";
import ContractType from "../static/icons/ContractType";
import EmployeeType from "../static/icons/EmployeeType";
import Sectors from "../static/icons/Sectors";
import Functions from "../static/icons/Functions";
import GroupFunctions from "../static/icons/GroupFunctions";
import Reasons from "../static/icons/Reasons";
import SocialSecretary from "../static/icons/SocialSecretary";
import HolidayCode from "../static/icons/HolidayCode";
import EmailTemplate from "../static/icons/EmailTemplate";
import Translate from "../static/icons/Translate";
import ParameterIcon from "../static/icons/ParameterIcon";
import ExportIcon from "../static/icons/ExportIcon"
import OverTime from "../static/icons/OverTime";
import Taxes from "../static/icons/Taxes";
import FAQConfig from "../static/icons/FAQConfig";
import RolesIcon from "../static/icons/RolesIcon";
import NotificationIcon from "../static/icons/Notification";
import EmployeeSettingsIcon from "../static/icons/EmployeeSettingsIcon";
import NotificationsTemplateIcon from "../static/icons/NotificationsTemplateIcon";
import NightHourCostIcon from "../static/icons/NightHourCostIcon";
import { checkPermission } from "../utilities/CommonFunctions";
export default function Configurations() {

    const subTabStyle = "col-md-2 my-3 mx-4 shadow text-center border-0  ";
    const permissionsArr = JSON.parse(localStorage.getItem("permissions") || []);

    const ConfigurationTabs = [
        { title: t('CONTRACT_TYPE'), icon: <ContractType />, styleClass: subTabStyle, type: 'contract_type', permissions: ['superadmin'] },
        { title: t('EMPLOYEE_TYPES'), icon: <EmployeeType />, styleClass: subTabStyle, type: 'employee_type', permissions: ['superadmin'] },
        { title: t('SECTORS'), icon: <Sectors />, styleClass: subTabStyle, type: 'sectors', permissions: ['superadmin'] },
        { title: t('FUNCTIONS'), icon: <Functions />, styleClass: subTabStyle, type: 'functions', permissions: ['superadmin'] },
        { title: t('GROUP_FUNCTIONS'), icon: <GroupFunctions />, styleClass: subTabStyle, type: 'group_functions', permissions: ['superadmin'] },
        { title: t('MIN_SALARY'), icon: <Salaries />, styleClass: subTabStyle, type: 'min_salary', permissions: ['superadmin'] },
        { title: t('REASONS'), icon: <Reasons />, styleClass: subTabStyle, type: 'reasons', permissions: ['superadmin'] },
        { title: t('SOCIAL_SECRETARY'), icon: <SocialSecretary />, styleClass: subTabStyle, type: 'social_secretary', permissions: ['superadmin'] },
        { title: t('INTERIM_AGENCIES'), icon: <SocialSecretary />, styleClass: subTabStyle, type: 'interim-agencies', permissions: ['superadmin'] },
        { title: t('DEFAULT_PARAMETER'), icon: <ParameterIcon />, styleClass: subTabStyle, type: 'default_param', permissions: ['superadmin'] },
        { title: t('PARAMETER'), icon: <ParameterIcon />, styleClass: subTabStyle, type: 'parameters', permissions: ['superadmin'] },
        // { title: t('DIMONA_NAMESPACE'), icon: <ParameterIcon />, styleClass: subTabStyle, type: 'dimona_namespace' },
        { title: t('SOCIAL_SECRETARY_ENGINES'), icon: <SocialSecretary />, styleClass: subTabStyle, type: 'social_secretary_engines', permissions: ['superadmin'] },
        { title: t('ROLES'), icon: <RolesIcon />, styleClass: subTabStyle, type: 'roles', permissions: ['superadmin'] },
        { title: t('MANAGE_ADMINS'), icon: <RolesIcon />, styleClass: subTabStyle, type: 'manage_admins', permissions: ['superadmin'] },
        { title: t('ADD_NOTIFICATION'), icon: <NotificationIcon color="#073763" />, styleClass: subTabStyle, type: 'notifications', permissions: ['superadmin', 'moderator'] },
        // { title: t('ADD_NOTIFICATION'), icon: <NotificationIcon color="#073763" />, styleClass: subTabStyle, overview: 'no_overview', actionLink: '/notifications-form' },
        { title: t('MANAGE_EMPLOYEES'), icon: <EmployeeSettingsIcon color="#073763" />, styleClass: subTabStyle, type: 'manage_employees', permissions: ['superadmin', 'moderator'] },

    ]

    const HolidayConfigurationTabs = [
        { title: t("HOLIDAY_CODES"), icon: <HolidayCode />, styleClass: subTabStyle, type: 'holiday_code', permissions: ['superadmin'] },
        { title: t("HOLIDAY_CODE_CONFIGURATION"), icon: <HolidayCode />, styleClass: subTabStyle, type: 'holiday_code_configuration', permissions: ['superadmin'] },
        { title: t("PUBLIC_HOLIDAY_CONFIGURATION"), icon: <HolidayCode />, styleClass: subTabStyle, type: 'public_holiday_configuration', permissions: ['superadmin'] },
    ]

    const CommunicationTabs = [
        { title: t("EMAIL_TEMPLATES"), icon: <EmailTemplate />, styleClass: subTabStyle, type: 'email', permissions: ['superadmin'] },
        { title: t("TRANSLATIONS"), icon: <Translate />, styleClass: subTabStyle, type: 'translation', permissions: ['superadmin', 'moderator'] },
        // { title: ('Message '), icon: Email, styleClass: subTabStyle, type: 'message' },
        { title: t("CONTRACT_TEMPLATES"), icon: <ContractType />, styleClass: subTabStyle, type: 'contracts_template', permissions: ['superadmin'] },
        { title: t("NOTIFICATION_TEMPLATES"), icon: <NotificationsTemplateIcon color="#073763" />, styleClass: subTabStyle, type: 'notification_template', permissions: ['superadmin'] },
        { title: t("FAQ"), icon: <FAQConfig />, styleClass: subTabStyle, type: 'faq', permissions: ['superadmin', 'moderator'] },

    ]

    const SocialSecretaryAndConfigurationTabs = [
        { title: t("TAXES"), icon: <Taxes />, styleClass: subTabStyle, type: 'taxes', permissions: ['superadmin'] },
        { title: t("SALARY_COEFFICIENT"), icon: <Salaries />, styleClass: subTabStyle, type: 'salary_coefficient', permissions: ['superadmin'] },
        // { title: t("EXPORT_CONFIGURATION"), icon: <ExportIcon />, styleClass: subTabStyle, type: 'export_configuration' },
        // { title: t("OVERTIME"), icon: <OverTime />, styleClass: subTabStyle, type: 'overtime' },
        { title: t("WORKCODES"), icon: <OverTime />, styleClass: subTabStyle, type: 'workcodes', permissions: ['superadmin'] },
        { title: t("NIGHT_HOUR_COST"), icon: <NightHourCostIcon color="#073763" />, styleClass: subTabStyle, type: 'night_hour_cost', permissions: ['superadmin'] },

    ]

    const InvoicingTabs = [
        { title: t("INVOICING"), icon: <Taxes />, styleClass: subTabStyle, type: 'invoice', permissions: ['superadmin'] },
    ]
    return (
        <div className="right-container">
            <div className="company-tab-width mt-3 border bg-white">
                <h2 className="text-center my-4 text-color font-weight-bold">{t("CONFIGURATIONS")}</h2>
                <div className="configuration_body">
                    {checkPermission(permissionsArr, ['superadmin', 'moderator']) && <><h4 className="text-left pl-5 ml-4 text-color font-weight-bold">{t("EMPLOYEE_CONFIGURATIONS")}</h4>
                        <div className="d-flex mx-5 mb-0 pb-4 flex-wrap border-bottom">
                            {
                                ConfigurationTabs.map((val, index) => {
                                    if (checkPermission(permissionsArr, val.permissions)) {


                                        if (val.overview === 'no_overview') {
                                            return (
                                                <Card className="p-3 py-4" key={val.title} title={val.title} icon={val.icon} styleClass={val.styleClass} actionLink={val.actionLink} view={'configuration'}></Card>

                                            )
                                        } else {
                                            return (
                                                <Card className="p-3 py-4" key={val.title} title={val.title} icon={val.icon} styleClass={val.styleClass} actionLink={'/manage-configurations/' + val.type} view={'configuration'}></Card>
                                            )
                                        }
                                    }
                                })
                            }
                        </div></>}

                    {checkPermission(permissionsArr, ['superadmin']) && <><h4 className="text-left pl-5 mt-4 ml-4 text-color font-weight-bold">{t("HOLIDAY_CONFIGURATION")}</h4>
                        <div className="d-flex mx-5 mb-0 pb-4 flex-wrap border-bottom">
                            {
                                HolidayConfigurationTabs.map((val, index) => {
                                    if (checkPermission(permissionsArr, val.permissions)) {

                                        return (
                                            <Card className="p-3 py-4" key={val.title} title={val.title} icon={val.icon} styleClass={val.styleClass} actionLink={'/manage-holiday-configurations/' + val.type} view={'configuration'}></Card>
                                        )
                                    }
                                })
                            }
                        </div></>}
                    {checkPermission(permissionsArr, ['superadmin', 'moderator']) && <><h4 className="text-left pl-5 mt-4 ml-4 text-color font-weight-bold">{t("COMMUNICATION_CONFIGURATION")}</h4>
                        <div className="d-flex mx-5 mb-0 pb-4 flex-wrap border-bottom">
                            {
                                CommunicationTabs.map((val, index) => {
                                    if (checkPermission(permissionsArr, val.permissions)) {

                                        return (
                                            <Card className="p-3 py-4" key={val.title} title={val.title} icon={val.icon} styleClass={val.styleClass} actionLink={'/manage-communication-configurations/' + val.type} view={'configuration'}></Card>
                                        )
                                    }
                                })
                            }
                        </div></>}
                    {checkPermission(permissionsArr, ['superadmin']) && <><h4 className="text-left pl-5 mt-4 ml-4 text-color font-weight-bold">{t("SOCIAL_SECRETARY_AND_REPORTING")}</h4>
                        <div className="d-flex mx-5 mb-0 pb-4 flex-wrap border-bottom">
                            {
                                SocialSecretaryAndConfigurationTabs.map((val, index) => {
                                    return (
                                        <Card className="p-3 py-4" key={val.title} title={val.title} icon={val.icon} styleClass={val.styleClass} actionLink={'/manage-social-secretary-and-reporting-configurations/' + val.type} view={'configuration'}></Card>
                                    )
                                })
                            }
                        </div></>}
                    {checkPermission(permissionsArr, ['superadmin']) && <><h4 className="text-left pl-5 mt-4 ml-4 text-color font-weight-bold">{t("INVOICE_CONFIGURATION")}</h4>
                        <div className="d-flex mx-5 mb-0 pb-4 flex-wrap border-bottom">
                            {
                                InvoicingTabs.map((val, index) => {
                                    if (checkPermission(permissionsArr, val.permissions)) {

                                        return (
                                            <Card className="p-3 py-4" key={val.title} title={val.title} icon={val.icon} styleClass={val.styleClass} actionLink={'/configurations/invoice-configurations/' + val.type} view={'configuration'}></Card>
                                        )
                                    }
                                })
                            }
                        </div></>}
                </div>
            </div>
        </div>
    )
}
