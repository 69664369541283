import React, { useEffect, useState } from "react";
import FormsNew from "../molecules/FormsNew";
import { t } from "../../translations/Translation";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetEmployeeListApiurl, GetPlanningLogsApiUrl, GetLongtermPlanningActivityLogsApiUrl } from "../../routes/ApiEndPoints";
import { GetFormattedDate } from "../../utilities/CommonFunctions";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import PlanningLogsTable from "../atoms/PlanningLogsTable";
import { WeekDetailsTable } from "../atoms/DataTables";
import CustomPopup from "../../utilities/popup/CustomPopup";
export default function PlanningLogs() {

    const [formData, setFormData] = useState({
        "start_date": "",
        "end_date": "",
        "employee_profile_ids": []
    })

    const [employeesList, setEmployeesList] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [errors, setErrors] = useState([]);

    const [detailsPopup, setDetailsPopup] = useState(false)
    const [details, setDetails] = useState('')


    const tableHeaders = [
        {
            accessorKey: 'employee_name',
            header: t('EMPLOYEE_NAME'),
            minSize: 20,
            maxSize: 50,
            size: 30,
        },
        {
            accessorKey: 'details',
            header: t('DETAILS'),
            maxSize: 600,
            minSize: 100,
            size: 600,
            Cell: ({ row }) => (
                <div onClick={() => {
                    setDetailsPopup(true)
                    row?.original?.long_term_planning ? getLongtermPlanetails(row.original.id) : setDetails(prev => row.original.details);
                }} className="twoline-div">
                    {row.original?.details}
                </div>
            )
        },
        {
            accessorKey: 'log_date_time',
            header: t('LOG_DATE_AND_TIME'),
            minSize: 20,
            maxSize: 50,
            size: 20,
        },
        {
            accessorKey: 'plan_date',
            header: t('PLAN_DATE'),
            minSize: 20,
            maxSize: 50,
            size: 20,
        },
        {
            accessorKey: 'manager_name',
            header: t('MANAGER'),
            minSize: 20,
            maxSize: 50,
            size: 20,
        },
    ]

    useEffect(() => {
        AXIOS.service(GetEmployeeListApiurl, 'GET')
            .then((result) => {
                if (result?.success) {
                    setEmployeesList(result?.data);
                }
            })

    }, [])


    const setValues = (index, name, value, field) => {
        let newData = { ...formData }
        if (field !== 'dropdown') {
            newData[name] = value
        } else {
            setSelectedEmployees(value)
            let arr = []
            value.map((item) => {
                arr.push(item.value)
                return null
            })
            newData[name] = arr;
        }
        setFormData(newData);
    }

    const getLongtermPlanetails = (id) => {
        AXIOS.service(`${GetLongtermPlanningActivityLogsApiUrl}/${id}`, 'GET')
            .then((result) => {
                if (result?.success) {
                    setDetails(result.data)
                } else {
                    setErrors(result.message);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const CustomComponent = ({ data }) => {
        return (data?.event === 'updated' ?
            <div className="container d-flex m-0" style={{ fontSize: "0.7rem" }}>
                <div className="col-6 mr-2 p-0">
                    <h3>{t("OLD")}:</h3>
                    <ul className="font-14">
                        <li>{t("REPEATING_WEEK")}: {data?.old?.repeating_week}</li>
                        <li>{t("START_DATE")}: {data?.old?.start_date}</li>
                        <li>{t("END_DATE")}:{data?.old?.end_date}</li>
                    </ul>
                    <WeekDetailsTable weekDetails={data?.old?.week_details}></WeekDetailsTable>
                </div>
                <div className="col-6 m-0 p-0">
                    <h3>{t("NEW")}:</h3>
                    <ul className="font-14">
                        <li>{t("REPEATING_WEEK")}: {data?.new?.repeating_week}</li>
                        <li>{t("START_DATE")}:{data?.new?.start_date}</li>
                        <li>{t("END_DATE")}:{data?.new?.end_date}</li>
                    </ul>
                    <WeekDetailsTable weekDetails={data?.new?.week_details}></WeekDetailsTable>
                </div>
            </div>
            : data?.event === 'created' ?
                <div className="col-12 overflow-auto mr-2">
                    <h3>{`${t("CREATED")}:`}</h3>
                    <ul className="font-14">
                        <li>{t("REPEATING_WEEK")}: {data?.repeating_week}</li>
                        <li>{t("START_DATE")}: {data?.start_date}</li>
                        <li>{t("END_DATE")}: {data?.end_date}</li>
                        <li>{t("OVERLAP_DATES")}: {data?.overlap_dates?.toString()}</li>
                    </ul>
                    <WeekDetailsTable weekDetails={data?.week_details}></WeekDetailsTable>
                </div> : data?.event === 'deleted' ?
                    <div className="col-12 overflow-auto mr-2">
                        <h3>{`${t("DELETED")}:`}</h3>
                        <ul className="font-14">
                            <li>{t("REPEATING_WEEK")}: {data?.repeating_week}</li>
                            <li>{t("START_DATE")}: {data?.start_date}</li>
                            <li>{t("END_DATE")}: {data?.end_date}</li>
                            <li>{t("OVERLAP_DATES")}: {data?.overlap_dates?.toString()}</li>
                        </ul>
                        <WeekDetailsTable weekDetails={data?.week_details}></WeekDetailsTable>
                    </div>
                    : <>{data}</>)

    }

    const filterData = [
        { title: t("EMPLOYEE_NAME"), name: 'employee_profile_ids', required: false, options: employeesList, selectedOptions: selectedEmployees, isMulti: true, type: 'dropdown', style: "col-md-3 float-left" },
        { title: t("FROM_DATE"), name: 'start_date', required: false, type: 'date', style: "col-md-3 float-left" },
        { title: t("TO_DATE"), name: 'end_date', required: false, type: 'date', style: "col-md-3 float-left" },
    ]

    return (
        <div className="planning-body">
            {detailsPopup && <CustomPopup
                fullscreen={true}
                header
                title={t("DETAILS")}
                onHide={() => { setDetails(''); setDetailsPopup(false) }}
                size="xl"
                footer
            ><CustomComponent data={details} /></CustomPopup>}
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            <div className="bg-white mt-1">
                <FormsNew
                    view="filters"
                    formTitle={''}
                    formattedData={formData}
                    data={filterData}
                    SetValues={setValues}
                ></FormsNew>
            </div>
            <div className="bg-white mt-2">
                <PlanningLogsTable apiurl={GetPlanningLogsApiUrl} payload={formData} headers={tableHeaders}></PlanningLogsTable>
            </div>
        </div>
    );
}