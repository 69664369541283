import React, { useEffect, useState } from "react";
import { GetReversedDate, GetTimeDifference, getDatesForWeek, getFormattedDropdownOptions } from "../../utilities/CommonFunctions";
// import WorkStationIcon from "../../static/icons/Workstation.svg";
import { t } from "../../translations/Translation";
import DeleteIcon from "../../static/icons/Delete.svg";
import CostIcon from "../../static/icons/Euro.svg";
import ContractHoursIcon from "../../static/icons/Contract.svg";
import EditShiftIcon from "../../static/icons/EditShift.png";
import Dropdown from "../atoms/Dropdown";
import PlanItem from "./PlanItem";
import CreatePlanPopup from "./CreatePlanPopup";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { DeleteWeekPlans, GetEmployeeOptionsApiUrl, GetWeeklyPlanningApiUrl, CreateShiftsApiUrl, CreateShiftPlanApiUrl, GetEmployeeWeekPlansApiUrl, GetAvailabilitiesApiUrl } from "../../routes/ApiEndPoints";
import { ToastContainer, toast } from 'react-toastify';
import ModalPopup from "../../utilities/popup/Popup";
import CreateShifts from "./CreateShifts";
import Workstation from "../../static/icons/Workstation";
import AddLeaveForPlanPopup from "./AddLeaveForPlanPopup";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import PublicHolidayIcon from "../../static/icons/belgium.svg";
import { Tooltip as ReactTooltip } from "react-tooltip";



export default function WeeklyOverview({ enableShifts, weekNumber, year, locId, wsIds, EmpTypeIds, ChangeTab, availableSwitch, addLeaveRefresh, setDisableSwitch, workstationOptions, refreshStateFromParent, setSelectedWeekYear }) {

    // Const for days
    const days = [t('MONDAY'), t('TUESDAY'), t('WEDNESDAY'), t('THURSDAY'), t('FRIDAY'), t('SATURDAY'), t('SUNDAY')]
    const dates = getDatesForWeek(weekNumber, year)
    const [weekData, setWeekData] = useState([]);
    const [planPopup, setPlanPopup] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);
    const [employeeId, setEmployeeId] = useState();
    const [planningDate, setPlanningDate] = useState();
    const [planWid, setPlanWid] = useState();
    const [planningDetails, setPlanningDetails] = useState([]);
    const [dropDownData, setDropDownData] = useState({});
    const [updatePlan, setUpdatePlan] = useState(false)
    const [dataRefresh, setDataRefresh] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [deleteRequestData, setDeleteRequestData] = useState({});
    const [totalData, setTotalData] = useState({});
    const [createIndex, setCreateIndex] = useState();
    const [availData, setAvailData] = useState([])

    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];

    const [shiftPopupOpen, setShiftPopupOpen] = useState(false);
    const [shiftId, setShiftId] = useState({});
    const [shiftData, setShiftData] = useState({
        'location_id': '',
        'workstation_id': '',
        'shifts': []
    })

    const [leavePopup, setLeavePopup] = useState(false)
    const [planIdForLeave, setPlanIdForLeave] = useState("")
    const [errors, setErrors] = useState([]);
    const [publicHolidays, setPublicHolidays] = useState({});

    // const [planningData, setPlanningData] = useState({
    //     'employee_id': "",
    //     'location_id': "",
    //     'workstation_id': "",
    //     'function_id':'',
    //     'employee_type_id':'',
    //     'dates': [],
    //     'timings':[]
    // });


    useEffect(() => {

        let current_date = new Date()
        setSelectedWeekYear(current_date.getFullYear())

        let request_Data = {
            "week_number": weekNumber,
            "year": year
        }
        let employees
        AXIOS.service(GetEmployeeOptionsApiUrl, 'POST', request_Data)
            .then((result) => {
                if (result?.success) {
                    employees = result.data
                    setEmployeeList(result.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })

        // Setting the weekly plans data
        let requestData = {
            'location': locId,
            'workstations': wsIds,
            'employee_types': EmpTypeIds,
            'week': weekNumber,
            'year': year
        }
        setWeekData([])

        AXIOS.service(GetWeeklyPlanningApiUrl, 'POST', requestData)
            .then((result) => {
                if (result?.success) {
                    // setWeekData(result.data);

                    let arr = []
                    result.data.workstation_data.map((val, i) => {
                        if (val.employee.length === 0) {
                            val.employee = [{
                                // employee_name: <Dropdown options={result.data.employee_list} onSelectFunction={(e) => setEmployee(val.workstation_id, 0, e.value)}></Dropdown>,
                                status: true,
                                total: '',
                                plans: [{ data: [] }, { data: [] }, { data: [] }, { data: [] }, { data: [] }, { data: [] }, { data: [] }]
                            }]
                            arr.push(val)
                        } else {
                            arr.push(val)
                        }
                    })
                    setWeekData(arr)
                    setPublicHolidays(result.data.public_holidays);
                    setTotalData(result.data.total)
                    setDisableSwitch(false)
                }
            })
            .catch((error) => {
                console.log(error);
            })
       
    }, [dataRefresh, weekNumber, locId, wsIds, EmpTypeIds, addLeaveRefresh, refreshStateFromParent])//refreshStateFromParent is added if planned from employees availability popup

    const GetEmployeePlans = (eid, ws, index, isWsModified) => {

        if (isWsModified) {
            setDataRefresh(!dataRefresh)
        } else {
            let requestData = {
                'location': locId,
                'workstations': wsIds,
                'employee_types': EmpTypeIds,
                'workstation_id': ws,
                'week': weekNumber,
                'year': year,
                'employee_profile_id': eid
            }

            AXIOS.service(GetEmployeeWeekPlansApiUrl, 'POST', requestData)
                .then((result) => {
                    if (result?.success) {
                        // setWeekData(result.data);
                        let week_data = [...weekData]
                        week_data.map((val, i) => {
                            if (val.workstation_id === ws) {
                                let employees = [...val.employee]
                                let addStatus = false
                                if (employees?.length === 0) {
                                    employees[0] = result.data
                                    val.employee = employees
                                    week_data[i] = val

                                } else {
                                    employees.map((empData, j) => {
                                        if (empData.employee_id !== undefined) {
                                            if (empData.employee_id === eid) {
                                                addStatus = true
                                                employees[j] = result.data
                                                val.employee = employees
                                                week_data[i] = val
                                            }

                                        } else {
                                            if ((j === createIndex || j === index) && !addStatus) {
                                                employees[j] = result.data
                                                val.employee = employees
                                                week_data[i] = val
                                            }
                                            if (j === createIndex && addStatus) {
                                                employees.splice(j, 1)
                                                val.employee = employees
                                                week_data[i] = val
                                            }

                                        }
                                    })
                                }
                            }
                        })
                        setWeekData(week_data);
                        setTotalData(result.data.day_total)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }

    }

    // Function to add new row for adding new employee
    const addNewRow = (wid, weekArrData, empIndex) => {
        let week_arr = weekArrData.length === 0 ? [...weekData] : [...weekArrData]
        week_arr.map((data, index) => {
            if (data.workstation_id === wid) {
                let data_arr = { ...data }
                let emp_arr = [...data.employee]
                emp_arr.push({
                    // employee_name: <Dropdown options={employeeList} onSelectFunction={(e) => setEmployee(wid, empIndex, e.value)}></Dropdown>,
                    status: true,
                    total: '',
                    plans: [{ data: [] }, { data: [] }, { data: [] }, { data: [] }, { data: [] }, { data: [] }, { data: [] }]
                })
                data_arr.employee = emp_arr
                week_arr[index] = data_arr
            }
        })
        setWeekData(week_arr)
    }


    const DeleteApiCall = () => {
        let week_arr = [...weekData]
        weekData.map((data, index) => {
            if (data.workstation_id === deleteRequestData.workstation_id) {
                let data_arr = { ...data }
                let emp_arr = [...data.employee]
                emp_arr.splice(deleteRequestData.row_index, 1)
                data_arr.employee = emp_arr
                week_arr[index] = data_arr
            }
        })
        setWeekData(week_arr)

        AXIOS.service(DeleteWeekPlans, 'POST', deleteRequestData)
            .then((result) => {
                if (result?.success) {
                    setDataRefresh(!dataRefresh);
                    setWarningMessage('')
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    // Function to delete plan row for adding new employee
    const removeRow = (wid, row_index, eid, plans) => {
        if (!plans.status) {
            let requestData = {
                "employee_id": eid,
                "location_id": locId,
                "workstation_id": wid,
                "week": weekNumber,
                "year": year,
                "row_index": row_index,
            }

            setWarningMessage(t("WEEK_PLANNINGS_DELETE"))
            setDeleteRequestData(requestData);

        } else if (row_index !== 0) {

            let week_arr = [...weekData]
            weekData.map((data, index) => {
                if (data.workstation_id === wid) {
                    let data_arr = { ...data }
                    let emp_arr = [...data.employee]
                    emp_arr.splice(row_index, 1)
                    data_arr.employee = emp_arr
                    week_arr[index] = data_arr
                }
            })
            setWeekData(week_arr)
        }
    }

    // Function to return total data element
    const getTotalData = (index, data) => {
        return (
            <td key={index} className={index === 7 ? " border-0" : "px-2"}>
                <div className="d-flex justify-content-between">
                    <small>{data?.cost !== null && <img src={CostIcon} alt={t("COST_TITLE")} className="plan-icon mr-1"></img>}{data?.cost}</small>
                    <small>{data?.contract_hours !== null && <img src={ContractHoursIcon} alt={t("CONTRACT_HOURS")} className="plan-icon mr-1"></img>}{data?.contract_hours}</small>
                </div>
            </td>
        )
    }

    useEffect(() => {
        let request_data = {
            "week": weekNumber,
            "year": year,
        }
        if (availableSwitch) {
            AXIOS.service(GetAvailabilitiesApiUrl, 'POST', request_data)
                .then((result) => {
                    if (result?.success) {
                        setAvailData(result.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        } else {
            setAvailData([])
        }
    }, [availableSwitch, weekNumber, year])


    const openCreatePlanPopup = (emp_id, date, ws, planData, ws_emp_index) => {
        let eid = emp_id

        // if (eid === undefined) {
        //     eid = employeeId[ws][ws_emp_index + 1]
        setCreateIndex(ws_emp_index)
        // } else {
        //     // setEmployee(ws, ws_emp_index, emp_id)
        //     // setCreateIndex(ws_emp_index)
        // }
        setEmployeeId(emp_id)
        setDropDownData({})

        if (eid) {
            if (enableShifts) {
                if (shiftId[ws]) {
                    let reqData = {
                        "employee_id": eid,
                        "location_id": locId,
                        "workstation_id": ws,
                        "shift_id": shiftId[ws]?.value,
                        "date": date
                    }
                    AXIOS.service(CreateShiftPlanApiUrl, 'POST', reqData)
                        .then((result) => {
                            if (result?.success) {
                                if (result.plan_created) {
                                    // setDataRefresh(!dataRefresh);
                                    GetEmployeePlans(eid, ws, ws_emp_index)
                                    toast.success(result.message[0], {
                                        position: "top-center",
                                        autoClose: 2000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "colored",
                                    });
                                } else {
                                    setPlanPopup(true);
                                    let [start, end] = shiftId[ws]?.label.split('-')
                                    end = end.split(' ')[0]
                                    let contract_hr = GetTimeDifference(start, end)
                                    if (planData[date]) {
                                        planData[date]['planning'] = [{ 'start_time': start, 'end_time': end, 'contract_hours': contract_hr, 'extra_info': '' }]
                                    } else {
                                        planData[date] = {}
                                        planData[date]['planning'] = [{ 'start_time': start, 'end_time': end, 'contract_hours': contract_hr, 'extra_info': '' }]
                                    }
                                    setPlanningDetails(planData[date]['planning'])
                                }
                            } else {
                                toast.error(result.message[0], {
                                    position: "top-center",
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                } else {
                    toast.error(t("PLEASE_SELECT_SHIFTS_TO_ADD_PLAN"), {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            } else {
                setPlanPopup(true);
            }
        }

        setPlanningDate(date)
        setPlanWid(ws);

        if (planData && planData[date] !== undefined) {
            setDropDownData({
                'employee_type': planData[date]['employee_type'],
                'function': planData[date]['function']
            })
            if (planData[date]['planning'] !== undefined) {
                planData[date]['planning']?.map((val) => {
                    val['start_time'] = val.timings?.split(' ')[0]
                    val['end_time'] = val.timings?.split(' ')[1]
                    // dummy data to check new flow of add leave and plan in one flow
                    // val.leave_status = true
                    // val.reason = "some reason"
                    // val.holiday_code_id ='123'
                })
            }
        }
        setPlanningDetails(planData && planData[date] !== undefined && planData[date]['planning'] !== undefined ? planData[date]['planning'] : [])
        setUpdatePlan(planData && planData[date] !== undefined && planData[date]['planning'] !== undefined ? true : false)
    }

    const SaveShift = () => {
        AXIOS.service(CreateShiftsApiUrl, 'POST', shiftData)
            .then((result) => {
                if (result?.success) {
                    setDataRefresh(!dataRefresh);
                    setShiftPopupOpen(false)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result?.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <div className="col-md-12 p-0 text-center panning_overview_table">
            {warningMessage && <ModalPopup
                title={t("WARNING_TITLE")}
                body={(warningMessage)}
                onConfirm={DeleteApiCall}
                onHide={() => setWarningMessage('')}
            ></ModalPopup>}
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            {shiftPopupOpen && UserPermissions?.includes('modify_preferred_shifts') && <CreateShifts setShiftPopupOpen={setShiftPopupOpen} setShiftData={setShiftData} shiftData={shiftData} SaveShift={SaveShift}></CreateShifts>}
            {planPopup && UserPermissions?.includes('modify_planning') && <CreatePlanPopup setPlanPopup={setPlanPopup} wid={planWid} enableShift={enableShifts} employeeId={employeeId} planningDate={planningDate} locId={locId} planData={planningDetails} dropDownData={dropDownData} updatePlan={updatePlan} dataRefresh={dataRefresh} setDataRefresh={setDataRefresh} GetEmployeePlans={GetEmployeePlans} setLeavePopup={setLeavePopup} setPlanIdForLeave={setPlanIdForLeave} workstationOptions={workstationOptions}></CreatePlanPopup>}
            {leavePopup && UserPermissions?.includes('modify_leaves') && <AddLeaveForPlanPopup leavePopup={leavePopup} setLeavePopup={setLeavePopup} setPlanPopup={setPlanPopup} planIdForLeave={planIdForLeave} setPlanIdForLeave={setPlanIdForLeave} dataRefresh={dataRefresh} setDataRefresh={setDataRefresh}></AddLeaveForPlanPopup>}
            <table className="table table-bordered mb-0 Overview_table_workstation">
                <thead className="sticky">
                    <tr>
                        <th className="date-head pt-4"><span><Workstation /></span></th>
                        <th className="py-4 date-head">{t("EMPLOYEES_TITLE")}</th>
                        {days.map((val, index) => {
                            return (
                                <th key={val} onClick={() => ChangeTab('day', new Date(GetReversedDate(dates[index])))} className="date-head">
                                    <div>{val}</div>
                                    <div>{dates[index]}</div>
                                    {publicHolidays && publicHolidays[dates[index]] !== undefined && <div><img src={PublicHolidayIcon} className="belgium-flag" data-tooltip-id={publicHolidays[dates[index]]} alt="Public holiday"></img>
                                        <ReactTooltip
                                            id={publicHolidays[dates[index]]}
                                            place="top"
                                            globalEventOff="click"
                                            content={publicHolidays[dates[index]]}
                                        />
                                    </div>}
                                </th>
                            )
                        })}
                        <th className="py-4 date-head">{t("TOTAL_TITLE")}</th>
                        <th className="py-4 date-head">{t("ACTIONS")}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        weekData.map((ws, index) => {
                            return (
                                ws.employee.map((ws_employee, ws_emp_index) => {
                                    return (
                                        <tr key={ws_employee.employee_name}>
                                            {/* Workstation column data */}
                                            {ws_emp_index === 0 && <td key={ws.workstation_id} className="justify-content-center py-3" rowSpan={ws.employee.length}>
                                                <div className="position-sticky top_100px">
                                                    <p className="mb-0">{ws.workstation_name}</p>
                                                    <h2 className="pointer" onClick={() => addNewRow(ws.workstation_id, [], ws.employee?.length)}>+</h2>
                                                    {enableShifts && <div className="row m-0 justify-content-center p-0">
                                                        <Dropdown
                                                            key={ws.workstation_id}
                                                            CustomStyle="col-md-8 p-0"
                                                            options={getFormattedDropdownOptions(ws.shifts, 'id', 'time')}
                                                            selectedOptions={shiftId[ws.workstation_id]}
                                                            onSelectFunction={(e) => setShiftId({ ...shiftId, [ws.workstation_id]: e })}
                                                        ></Dropdown>
                                                        <img className="shortcut-icon ml-2" alt="Add shift" onClick={() => { setShiftPopupOpen(true); shiftData['workstation_id'] = ws.workstation_id; shiftData['location_id'] = locId; shiftData['shifts'] = ws.shifts }} src={EditShiftIcon}></img>
                                                    </div>}
                                                </div>
                                            </td>}
                                            {/* Employee and plan data rows */}
                                            {/* <td>{ws_employee.employee_id ? <a className="text-dark text-truncate plannign_overview_weekly_employee_title" href={"/manage-employees/" + ws_employee.employee_id} title={ws_employee.employee_name}>{ws_employee.employee_name}</a> : ws_employee.employee_name}
                                                <div> {ws_employee.employee_id && ws_employee.employee_types !== null && ws_employee.employee_types !== undefined && Object.keys(ws_employee.employee_types).length !== 0 &&
                                                    //mapping employeetype and its colour
                                                    Object.keys(ws_employee.employee_types).map((key, index) => {
                                                        return (
                                                            <span key={index} title={key}><EmployeeType_icon IconColour={ws_employee.employee_types[key] ? ws_employee.employee_types[key] : " #61bfb5"} /></span>
                                                        )
                                                    })
                                                }</div></td> */}
                                            <PlanItem PlansData={ws_employee.plans} ws_employee={ws_employee} employeeList={employeeList} data={availData} availableSwitch={availableSwitch} wid={ws.workstation_id} Dates={dates} openCreatePlanPopup={openCreatePlanPopup} ws_emp_index={ws_emp_index} weekNumber={weekNumber} year={year} setDataRefresh={setDataRefresh} dataRefresh={dataRefresh}></PlanItem>
                                            <td>
                                                <div className="d-flex mt-3 justify-content-between">
                                                    {ws_employee.total?.cost !== undefined && ws_employee.total?.cost !== null && <small>
                                                        <img src={CostIcon} className="plan-icon mr-1" alt={t('COST_TITLE')}></img>
                                                        {ws_employee.total.cost}
                                                    </small>}
                                                    {ws_employee.total.contract_hours !== undefined && ws_employee.total.contract_hours !== null && <small>
                                                        <img src={ContractHoursIcon} className="plan-icon mr-1" alt={t("CONTRACT_HOURS")}></img>
                                                        {ws_employee.total.contract_hours}
                                                    </small>}
                                                </div>
                                            </td>
                                            <td>
                                                <img className="shortcut-icon pointer" onClick={() => removeRow(ws.workstation_id, ws_emp_index, ws_employee.employee_id, ws_employee)} src={DeleteIcon} title={t("DELETE")} alt={t("DELETE")}></img>
                                            </td>
                                        </tr>
                                    )
                                })
                            )
                        })
                    }
                    {/* Below code is to display total data at the bottom row */}
                    <tr>
                        <td className="border-0">{t("TOTAL_TITLE")}</td>
                        <td className="border-0"></td>
                        {
                            Object.keys(totalData).map(function (key, index) {
                                return (
                                    getTotalData(index, totalData[key])
                                )
                            })
                        }
                        <td className="border-0"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}