import React, { useEffect, useState } from "react"
import ErrorPopup from "../../utilities/popup/ErrorPopup"
import FormsNew from "./FormsNew"
import { useNavigate, useParams } from "react-router-dom"
import { t } from "../../translations/Translation"
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { toast } from "react-toastify"
import { GetSectorOptionsApiUrl, NightHourCostApiUrl } from "../../routes/ApiEndPoints"

export default function AddNightHourCost() {

    const navigate = useNavigate()
    const params = useParams()

    const [formData, setFormData] = useState({
        sector_id: "",
        from_date: "",
        to_date: "",
        cost: "",
        type:""
    })
    const [optionsList, setOptionsList] = useState([])
    const [selectedSector, setSelectedSector] = useState([])
    const [errors, setErrors] = useState([])
    const [success, setSuccess] = useState(true)
    const [type, setType] = useState("")


    useEffect(() => {

        AXIOS.service(GetSectorOptionsApiUrl, 'GET')
            .then((result) => {
                if (result?.success) {
                    setOptionsList(result?.data)
                }
            })
            .catch((error) => {
                console.log(error);
            })

        if (params.id) {
            AXIOS.service(NightHourCostApiUrl + '/' + params.id, 'GET')
                .then((result) => {
                    if (result?.success) {
                        let data = result.data
                        data.sector_id = result.data.sector?.value
                        const { sector, id, ...newData } = data
                        setFormData(newData)
                        setSelectedSector(result.data.sector)
                        setType(result.data.type)
                    } else {
                        setErrors(result.message)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [])

    const setValues = (index, name, value, field) => {
        let newData = { ...formData }
        if (field === 'dropdown') {
            setSelectedSector(value)
            newData[name] = value.value || ""
        } else {
            newData[name] = value
        }
        setFormData(newData)
    }

    const onSave = () => {

        let method = 'POST'
        let url = NightHourCostApiUrl
        if (params.id) { url = NightHourCostApiUrl + '/' + params.id; method = 'PUT' }

        AXIOS.service(url, method, formData)
            .then((result) => {
                if (result?.success) {
                    setSuccess(true)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                    })
                    navigate('/manage-social-secretary-and-reporting-configurations/night_hour_cost')
                } else {
                    setErrors(result.message)
                    setSuccess(true)
                }
            })
            .catch((error) => {
                console.log(error);
                setSuccess(true)
            })

    }

    const list = [{ key: "percentage", name: t('PERCENTAGE') }, { key: "value", name: t("VALUE") }]

    const changeCheckbox = (name, value ) => {

        setType(prev => value)
        setFormData({...formData, ['type']: value })

    }

    const fields_array = [
        { title: t("SECTOR"), name: "sector_id", required: true, options: optionsList, selectedOptions: selectedSector, type: "dropdown", style: 'col-12 mt-4 float-left' },
        { title: t("FROM_DATE"), name: "from_date", required: true, type: "date", style: 'col-6 mt-4 float-left' },
        { title: t("TO_DATE"), name: "to_date", required: true, type: "date", style: 'col-6 mt-4 float-left' },
        { title: t("TYPE"), name: "type", required: true, radiobuttonsList: list, selectedOption: type, changeCheckbox: changeCheckbox, type: "radio", style: 'p-0 col-12 mt-4 float-left' },
        (type ? { title: type === 'value' ? t("VALUE") : t("WAGE_PERCENTAGE"), name: "cost", required: true, type: "text", style: 'col-6 mt-2 float-left' } : {}),
    ]

    return (
        <div className="right-container add_night_hours_cost">
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            <FormsNew
                view={'add_night_hours'}
                formTitle={params.id ? t("EDIT_NIGHT_HOUR_COST") : t("ADD_NIGHT_HOUR_COST")}
                data={fields_array}
                SetValues={setValues}
                formattedData={formData}
                redirectURL={'/manage-social-secretary-and-reporting-configurations/night_hour_cost'}
                OnSave={onSave}
                Success={success}
                setSuccess={setSuccess}
            ></FormsNew>
        </div>
    )
}