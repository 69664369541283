import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Companies from '../pages/Companies';
import Settings from '../pages/Settings';

import Login from '../pages/Login';
import Employees from '../pages/Employees';
import Configurations from '../pages/Configurations';
import ConfigurationOverviews from '../components/organisms/ConfigurationOverviews';
import AddFunction from '../components/organisms/AddFunction';
import AddEmployeeTypes from '../components/organisms/AddEmployeeTypes';
import AddInterimAgency from '../components/organisms/AddInterimAgency';
import AddSector from '../components/organisms/AddSector';
import AddGroupFunction from '../components/organisms/AddGroupFunction';
import AddContractType from '../components/organisms/AddContractType';
import CompanyCreation from '../components/organisms/CompanyCreation';
import HolidayCodeCreation from '../components/molecules/HolidayCodeCreation';
import MyAccount from "../pages/MyAccount";
import EmployeeCreation from '../components/organisms/EmployeeCreation';
import HolidayCodeConfigurationOverview from '../components/organisms/HolidayCodeConfigurationOverview';
import CommunicationConfigurationOverview from '../components/organisms/CommunicationConfigurationOverview';
// const langRegex = "(en|nl|fr)?";
// const langPath =  `/:lang${langRegex}`;
import AddReasons from '../components/organisms/AddReasons';
import AddSocialSecretary from '../components/organisms/AddSocialSecretary';
import LinkHolidayCodeToSocialSecretary from "../components/molecules/LinkHolidayCodeToSocialSecretary"
import Planning from '../pages/Planning';
import AddEmailTemplate from '../components/organisms/AddEmailTemplate';
import AddContractsTemplate from '../components/organisms/AddContractsTemplate'
import Uurrooster from '../pages/Uurrooster';
import ClonePlanning from '../components/molecules/ClonePlanning';
import OthPlanning from '../components/organisms/OthPlanning';
import AddOthPlans from '../components/organisms/AddOthPlans';
import ViewOpenShiftDetails from '../components/molecules/ViewOpenShiftDetails';
import AddPublicHoliday from '../components/molecules/AddPublicHoliday';
import EmployeeBasicDetails from '../components/organisms/EmployeeBasicDetails';
import SocialSecretaryAndReportingConfigurationOverview from "../components/organisms/SocialSecretaryAndReportingConfigurationOverview"
import AddTaxes from "../components/molecules/AddTaxes"
import MonthlyOverview from '../pages/MonthlyOverview';
import AddSalaryCoefficient from '../components/molecules/AddSalaryCoefficient';
import ImportStatusOverView from '../components/molecules/ImportStatusOverView';
import AddDimonaNamespace from '../components/molecules/AddDimonaNamespace';
import AddOvertime from '../components/molecules/AddOvertime';
import AddFlexSalary from '../components/molecules/AddFlexSalary';
import AddEventPlanning from '../components/molecules/AddEventPlanning';
import ResetForgotPassword from '../components/molecules/ResetForgotPassword';
import MonthlyEmployeeOverview from '../components/organisms/Reporting/MonthlyEmployeeOverview';
import FaqOverview from "../components/molecules/FaqOverview"
import AddSocialSecretaryEngine from '../components/organisms/AddSocialSecretaryEngine';
import EventEmployeeList from '../components/molecules/EventEmployeeList';
import AddWorkCode from '../components/molecules/AddWorkCode';
import InvitedEmployeeOverview from '../components/molecules/InvitedEmployeesOverview';
import AddRole from '../components/molecules/AddRole';
import RolePermissions from '../components/molecules/RolePermissions';
import CurrentPlan from '../pages/CurrentPlan';
import ManageCompanyGroups from '../components/molecules/ManageCompanyGroup';
import AddAdmin from '../components/organisms/AddAdmin';
import AddNotifiction from '../components/organisms/AddNotifications';
import EmployeeCompanyDetails from '../components/molecules/EmployeeCompanyDetails';
import { InvoiceConfigurationOverview } from '../components/organisms/InvoiceConfigurationOverview';
import { AddNotificationTemplate } from '../components/organisms/AddNotificationTemplate';
import AddNightHourCost from '../components/molecules/AddNightHourCost';

const BaseRouter = ({ setAuth, setCompany }) => (
    <Routes>
        {/* route starts here */}
        {/* <Route exact path={active language}  element={<Component name />} /> */}
        <Route exact path="/" element={<Dashboard></Dashboard>} />
        <Route exact path="/login" element={<Login></Login>} />

        <Route exact path="/manage-employees" element={<Employees></Employees>} />
        <Route exact path="/manage-employees/:id" element={<Employees></Employees>} />
        <Route exact path="/add-employees" element={<EmployeeCreation></EmployeeCreation>} />
        <Route exact path="/add-employees/:id" element={<EmployeeCreation></EmployeeCreation>} />

        <Route exact path="/manage-companies" element={<Companies setCompany={setCompany}></Companies>} />
        <Route exact path="/manage-companies/:addType/:id" element={<CompanyCreation setCompany={setCompany} />} />

        <Route exact path='/configurations' element={<Configurations></Configurations>} />
        <Route exact path='/manage-configurations/:type' element={<ConfigurationOverviews></ConfigurationOverviews>} />
        {/* <Route exact path='/manage-configurations/:type/:id' element={<ConfigurationOverviews></ConfigurationOverviews>} /> */}

        <Route exact path='/add-function' element={<AddFunction></AddFunction>} />
        <Route exact path='/add-function/:id' element={<AddFunction></AddFunction>} />

        <Route exact path='/add-contract-type' element={<AddContractType></AddContractType>} />
        <Route exact path='/add-contract-type/:id' element={<AddContractType></AddContractType>} />

        <Route exact path='/add-employee-type' element={<AddEmployeeTypes></AddEmployeeTypes>} />
        <Route exact path='/add-employee-type/:id' element={<AddEmployeeTypes></AddEmployeeTypes>} />
        <Route exact path='/add-interim-agency' element={<AddInterimAgency></AddInterimAgency>} />
        <Route exact path='/add-interim-agency/:id' element={<AddInterimAgency></AddInterimAgency>} />

        <Route exact path='/add-sector' element={<AddSector></AddSector>} />
        <Route exact path='/add-sector/:id' element={<AddSector></AddSector>} />

        <Route exact path='/add-group-function' element={<AddGroupFunction></AddGroupFunction>} />
        <Route exact path='/add-group-function/:id' element={<AddGroupFunction></AddGroupFunction>} />

        <Route exact path='/add-holiday-code' element={<HolidayCodeCreation></HolidayCodeCreation>} />
        <Route exact path='/add-holiday-code/:id' element={<HolidayCodeCreation></HolidayCodeCreation>} />

        <Route exact path='/my-account' element={<MyAccount setAuth={setAuth}></MyAccount>} />
        <Route exact path='/settings' element={<Settings></Settings>} />
        <Route exact path='/manage-holiday-configurations/:type' element={<HolidayCodeConfigurationOverview></HolidayCodeConfigurationOverview>} />

        <Route exact path='/add-reasons' element={<AddReasons></AddReasons>} />
        <Route exact path='/add-reasons/:id' element={<AddReasons></AddReasons>} />

        <Route exact path='/add-social-secretary' element={<AddSocialSecretary></AddSocialSecretary>} />
        <Route exact path='/add-social-secretary/:id' element={<AddSocialSecretary></AddSocialSecretary>} />

        <Route exact path='/add-social-secretary-engine' element={<AddSocialSecretaryEngine></AddSocialSecretaryEngine>} />
        <Route exact path='/add-social-secretary-engine/:id' element={<AddSocialSecretaryEngine></AddSocialSecretaryEngine>} />

        <Route exact path='/link-holiday-code/:id' element={<LinkHolidayCodeToSocialSecretary></LinkHolidayCodeToSocialSecretary>} />


        <Route exact path="/manage-plannings" element={<Planning setHeaderCompanyDropdown={setCompany}></Planning>} />
        <Route exact path="/manage-plannings/:type/:id" element={<ViewOpenShiftDetails></ViewOpenShiftDetails>} />
        <Route exact path="/clone-plannings/:id" element={<ClonePlanning></ClonePlanning>} />

        <Route exact path='/add-email-template' element={<AddEmailTemplate></AddEmailTemplate>} />
        <Route exact path='/add-email-template/:id' element={<AddEmailTemplate></AddEmailTemplate>} />

        <Route exact path='/manage-communication-configurations/:type' element={<CommunicationConfigurationOverview></CommunicationConfigurationOverview>} />

        <Route exact path='/add-contracts-template/:addType' element={<AddContractsTemplate></AddContractsTemplate>} />
        <Route exact path='/add-contracts-template/:addType/:id' element={<AddContractsTemplate></AddContractsTemplate>} />

        <Route exact path='/uurrooster' element={<Uurrooster></Uurrooster>} />

        <Route exact path='/oth-planning/:eid' element={<OthPlanning></OthPlanning>} />
        <Route exact path='/create-oth-plans/:eid' element={<AddOthPlans></AddOthPlans>} />
        <Route exact path='/update-oth-plans/:eid/:id' element={<AddOthPlans></AddOthPlans>} />

        <Route exact path='/add-public-holiday' element={<AddPublicHoliday></AddPublicHoliday>} />
        <Route exact path='/add-public-holiday/:id' element={<AddPublicHoliday></AddPublicHoliday>} />

        <Route exact path='/manage-social-secretary-and-reporting-configurations/:type' element={<SocialSecretaryAndReportingConfigurationOverview></SocialSecretaryAndReportingConfigurationOverview>} />
        <Route exact path='/add-taxes' element={<AddTaxes></AddTaxes>} />
        <Route exact path='/add-taxes/:id' element={<AddTaxes></AddTaxes>} />
        <Route exact path='/add-salary-coefficient' element={<AddSalaryCoefficient></AddSalaryCoefficient>} />
        <Route exact path='/add-salary-coefficient/:id' element={<AddSalaryCoefficient></AddSalaryCoefficient>} />
        <Route exact path='/add-overtime' element={<AddOvertime></AddOvertime>} />
        <Route exact path='/add-overtime/:id' element={<AddOvertime></AddOvertime>} />

        <Route exact path='/add-workcode' element={<AddWorkCode></AddWorkCode>} />
        <Route exact path='/add-workcode/:id' element={<AddWorkCode></AddWorkCode>} />

        <Route exact path='/import-employees' element={<ImportStatusOverView></ImportStatusOverView>} />

        <Route exact path='/monthly-overview' element={<MonthlyOverview></MonthlyOverview>} />
        <Route exact path='/monthly-overview/:id' element={<MonthlyEmployeeOverview></MonthlyEmployeeOverview>} />


        <Route exact path='/add-dimona-namespace' element={<AddDimonaNamespace></AddDimonaNamespace>} />
        <Route exact path='/add-dimona-namespace/:id' element={<AddDimonaNamespace></AddDimonaNamespace>} />

        <Route exact path='/add-flex-salary' element={<AddFlexSalary></AddFlexSalary>} />
        <Route exact path='/add-event' element={<AddEventPlanning></AddEventPlanning>} />
        <Route exact path='/event-employees' element={<EventEmployeeList></EventEmployeeList>} />

        <Route exact path='/faq-overview' element={<FaqOverview></FaqOverview>} />
        <Route exact path='/faq' element={<FaqOverview></FaqOverview>} />
        <Route exact path='/manage-invites' element={<InvitedEmployeeOverview></InvitedEmployeeOverview>} />
        <Route exact path='/add-role/:id?' element={<AddRole></AddRole>} />
        <Route exact path='/role-permissions/:id' element={<RolePermissions></RolePermissions>}></Route>
        <Route exact path='/current-plan' element={<CurrentPlan setAuth={setAuth} />}></Route>
        <Route exact path='/manage-company-groups' element={<ManageCompanyGroups></ManageCompanyGroups>} />
        <Route exact path='/add-admin' element={<AddAdmin></AddAdmin>} />
        <Route exact path='/add-admin/:id' element={<AddAdmin></AddAdmin>} />
        <Route exact path='/employee-company-details/:id' element={<EmployeeCompanyDetails />}></Route>

        <Route exact path='/notifications-form' element={<AddNotifiction></AddNotifiction>} />
        <Route exact path='/configurations/invoice-configurations/:type' element={<InvoiceConfigurationOverview></InvoiceConfigurationOverview>} />
        <Route exact path='/configurations/add-notification-template/:id' element={<AddNotificationTemplate></AddNotificationTemplate>} />
        <Route exact path='/configurations/add-nighthour-cost' element={<AddNightHourCost></AddNightHourCost>} />
        <Route exact path='/configurations/add-nighthour-cost/:id' element={<AddNightHourCost></AddNightHourCost>} />

    </Routes>
);

const InviteRoute = () => (
    <Routes>
        <Route exact path='/employee-invitations/:validtoken' element={<EmployeeBasicDetails></EmployeeBasicDetails>} />
    </Routes>
)

const ResetPasswordRoute = () => (
    <Routes>
        <Route exact path='/reset-password/:validtoken' element={<ResetForgotPassword></ResetForgotPassword>} />
    </Routes>
)

export default BaseRouter;
export { InviteRoute, ResetPasswordRoute }